import { Options, RangeSelectorButtonsOptions, RangeSelectorOptions } from 'highcharts'
import { getVar } from '../../../layout'
import { getColor } from '../../../themes'
import { RangeButton, StockChartOptions } from '../../../types'

const getButtons: () => RangeButton[] = () => [
  {
    type: 'minute',
    text: 'live',
    kind: 'live',
  },
  {
    count: 5,
    type: 'minute',
    text: '5',
  },
  {
    count: 15,
    type: 'minute',
    text: '15',
  },
  {
    count: 30,
    type: 'minute',
    text: '30',
  },
  {
    count: 1,
    type: 'hour',
    text: '1h',
  },
  {
    count: 2,
    type: 'hour',
    text: '2h',
  },
  {
    count: 4,
    type: 'hour',
    text: '4h',
  },
  {
    count: 8,
    type: 'hour',
    text: '8h',
  },
  {
    count: 12,
    type: 'hour',
    text: '12h',
  },
  {
    count: 24,
    type: 'hour',
    text: '24h',
  },
  {
    count: 48,
    type: 'hour',
    text: '48h',
  },
  {
    count: 7,
    type: 'day',
    text: '1w',
  },
  {
    type: 'all',
    text: '2w',
  },
]

export const getRangeSelectorConfig: () => RangeSelectorOptions = () => ({
  floating: true,
  inputEnabled: false,
  selected: 6,
  buttons: getButtons() as RangeSelectorButtonsOptions[],
})

export const getNavigatorConfig = ({ height, backgroundColor, fontColor, onClick }: StockChartOptions) => {
  const stockChartConfig: Options = {
    chart: {
      height: height,
      backgroundColor: undefined,
      spacing: [0, 10, 0, 0],
      margin: [0, 2, 0, 0],
      marginLeft: getVar('chart-left-margin'),
    },
    navigator: {
      margin: 5,
      maskFill: getColor('--navigator-mask-color'),
      //   maskFill: 'rgba(75, 75, 75, 0.85)',
      maskInside: false,
      xAxis: {
        ordinal: false,
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
      },
      //   series: [{ lineWidth: 0, fillOpacity: 0 }],
      outlineWidth: 0,
      //   outlineColor: getColor('--navigator-mask-color'),
      yAxis: {
        plotBands: [
          {
            from: -100,
            to: 100,
            color: backgroundColor,
          },
        ],
      },
    },
    accessibility: {
      enabled: false,
    },
    time: {
      useUTC: false,
    },
    tooltip: {
      enabled: true,
      xDateFormat: '%Y-%m-%d',
      outside: true,
    },
    rangeSelector: getRangeSelectorConfig(),
    title: {
      text: undefined,
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      ordinal: false,
      type: 'datetime',
      dateTimeLabelFormats: {
        hour: '%l:%M %P',
      },
      labels: {
        enabled: false,
        style: { color: fontColor, fontSize: '10px' },
      },
      minPadding: 0,
      maxPadding: 0,
      lineWidth: 0,
      tickWidth: 0,
    },
    yAxis: {
      height: 0,
      //   min: 0,
      //   max: 100,
      title: {
        text: undefined,
        style: {
          color: fontColor,
        },
      },
      labels: {
        enabled: false,
        style: { color: fontColor },
      },
      gridLineWidth: 0,
      opposite: false,
      endOnTick: false,
    },
    scrollbar: {
      enabled: false,
    },
    series: [
      {
        name: 'value',
        type: 'line',
        showInNavigator: true,
        visible: true,
        navigatorOptions: {
          visible: true,
        },
      },
    ],
    plotOptions: {
      series: {
        lineWidth: 1,
        color: fontColor,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
  }
  return stockChartConfig
}
