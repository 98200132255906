import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Patient, PatientState } from '../../types'
import { ExportModal } from '../common/ExportModal'
import { toPatientDisplayText } from '../../utils'
import { UpdatePatientModal } from '../common'

export const PatientHeader: FC<{ patient: Patient }> = ({ patient }) => {
  const navigate = useNavigate()

  const handleBack = useCallback(() => {
    navigate(-1)
  }, [])

  return (
    <div className="v-header">
      <div onClick={handleBack} className="v-header-item v-header-item--backButton u-noSelect">
        Back
      </div>
      <div className="v-header-item v-header-item--bold">{toPatientDisplayText(patient)}</div>
      {/* <div className="v-header-item">DOB: {patient.dob?.toLocaleDateString()}</div> */}
      <div className="v-header-item">ID: {patient.id}</div>
      {/* <div className="v-header-item">Room: 26</div> */}
      <div className="v-header-item v-header-item--alignRight">
        <div className="v-row u-noSelect">
          <ExportModal patient={patient} />
          {patient.state === PatientState.ACTIVE ? (
            <>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <UpdatePatientModal
                patient={patient}
                mode={PatientState.INACTIVE}
                message="Please confirm you would like to deactivate this patient, they will no longer be shown in the dashboard view."
                onComplete={() => {
                  window.history.back()
                  window.history.replaceState(null, '', '.')
                }}
              >
                Deactivate
              </UpdatePatientModal>
            </>
          ) : undefined}
        </div>
      </div>
    </div>
  )
}
