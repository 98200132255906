import { FetchResult, gql, useApolloClient, ApolloCache } from '@apollo/client'
import { useEffect } from 'react'
import {
  Aggregate,
  ActiveDevice,
  ActivePatientEvent,
  PatientAssignedEvent,
  DeviceAttachedEvent,
  GraphQLResult,
  PatientStateUpdatedEvent,
  PatientState,
  GraphQLPagedResult,
  Patient,
} from '../types'
import { QUERY_PATIENTS } from './usePatients'

export const SUBSCRIBE_GROUP_ACTIVE_PATIENT_EVENT = gql`
  subscription ActivePatientGroupEvents($groupId: Int!) {
    result: activePatientGroupEvents(groupId: $groupId) {
      __typename
      ... on PatientAssignedEvent {
        patient {
          id
          firstName
          lastName
          reference
        }
        isAssigned
      }
      ... on DeviceAttachedEvent {
        deviceId
        fromPatient
        toPatient
      }
      ... on PatientStateUpdatedEvent {
        patient {
          id
          firstName
          lastName
          reference
          state
        }
      }
    }
  }
`

const addPatient = (existingPatients: Patient[], patientToAdd: Patient) => {
  if (existingPatients.map((p) => p.id).includes(patientToAdd.id)) {
    return existingPatients
  }

  return existingPatients.concat(patientToAdd)
}

const removePatient = (existingPatients: Patient[], patientIdToRemove: string) => {
  const index = existingPatients.map((p) => p.id).indexOf(patientIdToRemove)
  if (index >= 0) {
    return existingPatients.filter((_value, i) => i !== index)
  }
  return existingPatients
}

const processPatientAttachEvent = (cache: ApolloCache<object>, evt: DeviceAttachedEvent) => {
  const { deviceId, fromPatient, toPatient } = evt
  if (fromPatient) {
    cache.modify({
      id: 'PatientDevices:' + fromPatient,
      fields: {
        devices: (devices) => {
          return devices.filter((value: ActiveDevice) => {
            if (value.id === deviceId) {
              return false
            }
            return true
          })
        },
      },
    })
  }
  if (toPatient) {
    cache.modify({
      id: 'PatientDevices:' + toPatient,
      fields: {
        devices: (devices) => {
          if (devices.find((device: ActiveDevice) => device.id === deviceId)) {
            return devices
          }
          return devices.concat({
            id: deviceId,
            aggregates: [],
          } as ActiveDevice)
        },
      },
    })
  }
}

const movePatientBetweenPatientQueryCaches = (
  cache: ApolloCache<object>,
  groupId: number,
  patient: Patient,
  fromState?: PatientState,
  toState?: PatientState,
) => {
  if (fromState) {
    removePatientFromPatientQueryCache(cache, groupId, fromState, patient.id)
  }
  if (toState) {
    addPatientToPatientQueryCache(cache, groupId, toState, patient)
  }
}

const addPatientToPatientQueryCache = (
  cache: ApolloCache<object>,
  groupId: number,
  state: PatientState,
  patient: Patient,
) => {
  cache.updateQuery(
    {
      query: QUERY_PATIENTS,
      variables: {
        groupId,
        state,
      },
    },
    (data: GraphQLPagedResult<Patient> | null): GraphQLPagedResult<Patient> | null => {
      return {
        result: {
          __typename: 'PatientsConnection',
          nodes: addPatient(data?.result?.nodes ?? [], patient),
        },
      }
    },
  )
}

const removePatientFromPatientQueryCache = (
  cache: ApolloCache<object>,
  groupId: number,
  state: PatientState,
  patientId: string,
) => {
  cache.updateQuery(
    {
      query: QUERY_PATIENTS,
      variables: {
        groupId,
        state,
      },
    },
    (data: GraphQLPagedResult<Patient> | null): GraphQLPagedResult<Patient> | null => {
      return {
        result: {
          __typename: 'PatientsConnection',
          nodes: removePatient(data?.result?.nodes ?? [], patientId),
        },
      }
    },
  )
}

const onGroupEvent = (
  groupId: number,
  cache: ApolloCache<object>,
  result: FetchResult<GraphQLResult<ActivePatientEvent>>,
): void => {
  if (!result.data) {
    return
  }

  const evt = result.data.result
  if (!evt) {
    return
  }
  const { __typename } = evt
  if (__typename === 'PatientAssignedEvent') {
    const event = evt as PatientAssignedEvent

    if (event.isAssigned) {
      addPatientToPatientQueryCache(cache, groupId, PatientState.ACTIVE, event.patient)
    } else {
      removePatientFromPatientQueryCache(cache, groupId, PatientState.ACTIVE, event.patient.id)
    }
  } else if (__typename === 'DeviceAttachedEvent') {
    processPatientAttachEvent(cache, evt as DeviceAttachedEvent)
  } else if (__typename === 'PatientStateUpdatedEvent') {
    const event = evt as PatientStateUpdatedEvent
    const { patient } = event

    if (patient.state === PatientState.ARCHIVED) {
      removePatientFromPatientQueryCache(cache, groupId, PatientState.ACTIVE, patient.id)
      removePatientFromPatientQueryCache(cache, groupId, PatientState.INACTIVE, patient.id)
      return
    }

    const from = patient.state === PatientState.ACTIVE ? PatientState.INACTIVE : PatientState.ACTIVE
    const to = patient.state === PatientState.ACTIVE ? PatientState.ACTIVE : PatientState.INACTIVE
    movePatientBetweenPatientQueryCaches(cache, groupId, patient, from, to)
  } else {
    console.warn(`received unhandled event: ${__typename}`)
  }
}

export const useGroupSubscription = (groupId: number) => {
  const client = useApolloClient()
  useEffect(() => {
    const observable = client.subscribe<GraphQLResult<ActivePatientEvent>>({
      query: SUBSCRIBE_GROUP_ACTIVE_PATIENT_EVENT,
      variables: { groupId },
    })
    const subscription = observable.subscribe((next) => onGroupEvent(groupId, client.cache, next))
    return () => {
      subscription.unsubscribe()
    }
  }, [groupId])
}
