import { ApolloCache } from '@apollo/client'
import { uiStateService } from './UIStateService'

export const updatePatientStatus = (cache: ApolloCache<object>, patientId: string, newStatus: number) => {
  uiStateService.savePatientStatus(patientId, newStatus)
  setTimeout(() => {
    cache.modify({
      id: 'PatientDevices:' + patientId,
      fields: {
        status: () => newStatus,
      },
    })
  })
}
