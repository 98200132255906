import { lazy } from 'react'
import { SciChartSurface } from 'scichart'

export * from './HoverNotificationModifier'
export * from './LocalDateLabelProvider'
export * from './SciChart'
export * from './SciChartGroup'
export * from './SciScatterChart'
export * from './SciOverview'
export * from './TooltipModifier'

export const SciChartPane = lazy(() => import('./SciChartPane'))
export const SciNavigator = lazy(() => import('./SciNavigator'))
export const SciNotePanel = lazy(() => import('./SciNotePanel'))
export const SciTimeAxis = lazy(() => import('./SciTimeAxis'))

SciChartSurface.loadWasmFromCDN()
SciChartSurface.autoDisposeWasmContext = true

const licensed = localStorage.getItem('scichart')
if (window.location.hostname === 'localhost' && !licensed) {
  SciChartSurface.UseCommunityLicense()
} else {
  SciChartSurface.setRuntimeLicenseKey("1evjcz+Fbo3HtG4holzg1jJ5g+DeNw1JVx3BqUDKI93xtQZGUNrnFVkOUlKVRegX8lz9GlLEIvCN0Oknebvp5sdc2MqAuM1geiNe6oesJgR7mqZA0t9UQU89l8/9evQnfMVwx+YsIFUd6BlShNQ2tyvG6HdG/wCmKGotamM3oPwlpfV4oZ3k2taBh0DsEBw/8mlZkdePuOrtNtZsYTkn0iHk+2qBTBgPQTznGRsfc55u4MbRa7jA+TlhNpGSIyzo4ByS+8ptY48aoEBGWoUfwGlukf2BkDm3IwWo4nUrvcUx77AnUxxSX5YLri2zkHNFEASaRzfZ3+rfCcR7H42Hdm4jqDqKzVZjEmRZcAf0BObeZrjYCtMSLNSxF6ZVV3DHDkKo4q6EjvGBqaCh9Iy/9QTNgXG/11xxlwiyGzKuKECy+fMSP+V++zzkeaygfIGpemKxStpkkeMUVN5KSRxYfEGaS1zcsT4kYH7vC6pRD2KNtd/UTNFcaQVVsmpQujnLWMbEMbGui6Rnstiv36nJrNB6Q//aq6cdb63s84MeD/pmqjq6gt2vAbxwjVkXzZ2yMMMIn9KsEgy8AmbNxoRL//CIOIvEIj3Jvr9vWH6MI04qJLEz5SRcZb7TPYQdpXjLavUNMgi88R6RN1hl4B9l9bsy+CUyOHZqlFJy6ZoZ07/ZOt9Pr6igYTiskj7Y1CU0OWG4RrNzoUsaImX3Qv4FoJokpLAfx9234ioR1r/O85lQ/BxeDDCScuNSJnDUboKTr2Htzuvp8uIFftz6Y8RAKnPcsJaP4wjGFVJlS1lhIRZjkBBXlG9EKY4nTE+4qBT1+HeffKAFFWjU/DZPOKtcGbKEM/jtE235HwpLseJ1Fw==")
}
// MemoryUsageHelper.isMemoryUsageDebugEnabled = true
