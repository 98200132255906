export const inactiveSymbol = '-'

export const tileColorOpacity = '88'

export const locale = 'en-NZ'

export const minuteInMs = 60000
export const msInDay = 86400000
export const msIn2Weeks = 1209600000

export const dataGapSec = 150
