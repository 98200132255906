import { gql, useQuery } from '@apollo/client'
import { PatientDevicesQueryResult } from '../types'

export const QUERY_PATIENT_DEVICE = gql`
  query QueryPatientDevices($patientId: ID) {
    result: patientDevices(patientId: $patientId) {
      id
      status
      devices {
        id
        aggregates {
          name
          value
          updated
        }
      }
    }
  }
`

export const usePatientDevices = (patientId: string) => {
  const { data, loading, error } = useQuery<PatientDevicesQueryResult>(QUERY_PATIENT_DEVICE, {
    variables: { patientId },
  })

  return { data: data?.result, loading, error }
}
