/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'
import Select, { Options, SingleValue } from 'react-select'
import { getColor } from '../../themes'

export const StyledSelect: FC<{
  className?: string
  style: Record<string, string>
  options: Options<{ value: string; label: string }>
  defaultIndex?: number | undefined
  onChange?: (newValue: SingleValue<{ value: string; label: string }>) => void
}> = ({ className, style, options, defaultIndex, onChange }) => {
  const styles = {
    menuList: (base: object) => ({
      ...base,
      ...style,
    }),
    control: (base: object) => ({
      ...base,
      ...style,
      padding: '0px',
      borderStyle: 'none',
    }),
    singleValue: (base: object) => ({
      ...base,
      ...style,
      fontWeight: 'bold',
    }),
    // valueContainer: (base: object) => ({
    //   ...base,
    //   padding: '0px',
    // }),
    indicatorSeparator: (base: object) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base: object, state: any) => ({
      ...base,
      ...style,
      paddingLeft: '0px',
      opacity: state.isFocused ? 1 : 0.4,
      ':hover': {
        opacity: 1,
      },
    }),
    option: (base: any, state: any) => ({
      ...base,
      color: style.color,
      backgroundColor: state.isFocused ? getColor('--dropdown-hover') : style.backgroundColor,
      // paddingLeft: '0px',
    }),
  }
  return (
    <Select
      className={className}
      defaultValue={options[defaultIndex ?? 0]}
      isSearchable={false}
      onChange={(newValue) => onChange && onChange(newValue)}
      options={options}
      styles={styles}
    />
  )
}
