import { FC, Suspense, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PatientHeader, Toolbar } from '.'
import { Info } from '..'
import { ActiveDevice, AppState, DataRange, DateRange, Patient, UsageSegment } from '../../types'
import { TimeAxis, Navigator, VitalSurface } from '../chart'
import {
  useUsageSegments,
  usePatient,
  useDeviceGroups,
  usePatientService,
  useDisplayContext,
  useFirebaseRoutesAnalytics,
  useWasmSetting,
  useDeviceUpdatedSubscription,
  usePatientDevices,
} from '../../hooks'
import { NoteSurface } from '../chart/NoteSurface'
import { INoteService, IPatientService } from '../../services'
import { useNoteService } from '../../hooks/useNoteService'
import { useSelector } from 'react-redux'
import { uiSlice } from '../../store'
import { useDispatch } from 'react-redux'
import { SciTimeAxis, SciNavigator } from '../scichart'

export const PatientPage: FC = () => {
  const { patientId } = useParams()
  if (!patientId) return <Info text="Invalid patientId" />

  const patient = usePatient(patientId)
  const dispatch = useDispatch()
  const service = usePatientService(patientId)
  const noteService = useNoteService(patientId)
  useDeviceUpdatedSubscription(patientId)
  useFirebaseRoutesAnalytics()

  useEffect(() => {
    return () => {
      const start = useDisplayContext().start.getTime()
      dispatch(uiSlice.actions.setDataRange({ value: { start } }))
    }
  }, [])

  if (!patient || !service || !noteService) return <Info text="No patient data" />

  return <PatientContentContainer patient={patient} service={service} noteService={noteService} />
}

const PatientContentContainer: FC<{ patient: Patient; service: IPatientService; noteService: INoteService }> = ({
  patient,
  service,
  noteService,
}) => {
  const dataRange = useSelector<AppState, DataRange>((state) => state.ui.dataRange)
  if (!dataRange.value.start) {
    return <Info text="loading ..." />
  }

  return (
    <PatientContent
      patient={patient}
      service={service}
      noteService={noteService}
      dataRange={{
        start: new Date(dataRange.value.start),
        end: dataRange.value.end ? new Date(dataRange.value.end) : undefined,
      }}
    />
  )
}

const PatientContent: FC<{
  patient: Patient
  dataRange: DateRange
  service: IPatientService
  noteService: INoteService
}> = ({ patient, dataRange, service, noteService }) => {
  const usageSegments = useUsageSegments(patient.id, dataRange.start, dataRange.end)
  const { wasmChartEnabled } = useWasmSetting()

  if (!patient) {
    return <Info text="Error getting patient info" />
  }

  return (
    <div className="v-contentView">
      <div className="v-contentArea">
        <PatientHeader patient={patient} />
        <div className="v-layout">
          <div className="v-patientPanel">
            <Suspense fallback={<Info text="Loading ..." />}>
              {wasmChartEnabled ? (
                <SciTimeAxis id="vital-time-axis" showControl={true} />
              ) : (
                <TimeAxis id="intervention-time-axis" showControl={true} />
              )}
            </Suspense>
            {noteService ? <NoteSurface patient={patient} service={noteService} /> : undefined}
            <PatientDeviceView patient={patient} usageSegments={usageSegments} service={service} />
            <div className="v-patientPanel-footer">
              <Suspense fallback={<Info text="Loading ..." />}>
                {wasmChartEnabled ? (
                  <>
                    <SciTimeAxis id="vital-time-axis" showControl={true} />
                    <SciNavigator patient={patient} service={service} showControl={true} />
                  </>
                ) : (
                  <>
                    <TimeAxis id="vital-time-axis" showControl={true} />
                    <Navigator patient={patient} service={service} showControl={true} />
                  </>
                )}
              </Suspense>
            </div>
          </div>
          <div style={{ width: '20px' }}></div>
          <Toolbar patient={patient} />
        </div>
      </div>
    </div>
  )
}

const PatientDeviceView: FC<{ patient: Patient; usageSegments: UsageSegment[]; service: IPatientService }> = ({
  patient,
  usageSegments,
  service,
}) => {
  const { data, loading } = usePatientDevices(patient.id)
  if (loading) return <Info text="Loading ..." />

  return (
    <DeviceGroupView patient={patient} devices={data?.devices ?? []} usageSegments={usageSegments} service={service} />
  )
}

const DeviceGroupView: FC<{
  patient: Patient
  devices: ActiveDevice[]
  usageSegments: UsageSegment[]
  service: IPatientService
}> = ({ patient, devices, usageSegments, service }) => {
  const deviceGroups = useDeviceGroups(usageSegments, devices)
  if (!deviceGroups) {
    return <Info text="Loading ..." />
  }

  return (
    <>
      {deviceGroups?.map((group) =>
        group.data.length ? (
          <VitalSurface
            name={group.name}
            key={group.name}
            patient={patient}
            viewModels={group.data}
            service={service}
          />
        ) : null,
      )}
    </>
  )
}
