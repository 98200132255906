import { gql, useQuery } from '@apollo/client'
import { UsageSegment, GraphQLResult } from '../types'

const PATIENT_DATA_SEGMENTS_QUERY = gql`
  query QueryPatientDataSegments($id: UUID!, $start: DateTime!, $stop: DateTime) {
    result: patientDataSegments(patientId: $id, start: $start, stop: $stop) {
      deviceId
      metric
      begin
      end
    }
  }
`

export const useUsageSegments = (patientId: string | undefined, start?: Date, stop?: Date): UsageSegment[] => {
  const { loading, error, data } = useQuery<GraphQLResult<UsageSegment[]>>(PATIENT_DATA_SEGMENTS_QUERY, {
    variables: { id: patientId, start, stop },
    fetchPolicy: 'network-only',
  })
  if (!loading && !error && data && data.result) {
    return data.result
  }
  if (error) {
    console.warn(error)
  }
  return []
}
