import { ApolloClient, ApolloQueryResult, FetchResult, gql, Observable } from '@apollo/client'
import {
  DateRangeTimestamp,
  DeviceDataQuery,
  DeviceDataResult,
  DeviceDataSet,
  MetricSettings,
  TimeBucket,
} from '../types'
import { ConcurrentQueue } from './ConcurrentQueue'
import { IHttpClient } from '../infrastructure'
import { decode } from '../utils'
import { subSeconds } from 'date-fns'
import { dataCache, getOrCreateAggregator, createAggregator } from '.'
import { getBuckets, getSampleSize, getSampleSizeSec, isNowBucket } from './CacheManager'

const dataKey = 'data'
const trendDataKey = 'trend'

const QUERY_PATIENT_DATAPOINTSERIES = gql`
  query DeviceDataSeries(
    $patientId: UUID!
    $deviceId: String!
    $metricName: String!
    $start: DateTime!
    $stop: DateTime
    $sampleSize: String
  ) {
    deviceDataSeries(
      patientId: $patientId
      deviceId: $deviceId
      metricName: $metricName
      start: $start
      stop: $stop
      sampleSize: $sampleSize
    ) {
      name
      dataPoints
    }
  }
`

const QUERY_DATAPOINTS = gql`
  query DeviceData(
    $patientId: UUID!
    $deviceId: String!
    $metricName: String!
    $start: DateTime!
    $stop: DateTime
    $sampleSize: String
  ) {
    deviceData(
      patientId: $patientId
      deviceId: $deviceId
      metricName: $metricName
      start: $start
      stop: $stop
      sampleSize: $sampleSize
    )
  }
`

const ON_NEW_DEVICE_DATAPOINT_SUBSCRIPTION = gql`
  subscription OnNewPatientDeviceDataPoint($patientId: ID!) {
    newPatientDeviceDataPoints(patientId: $patientId) {
      deviceId
      series {
        name
        dataPoints
      }
    }
  }
`

type PatientDeviceDataPointResponse = {
  newPatientDeviceDataPoints: DeviceDataSet
}

export class PatientService implements IPatientService {
  private client: ApolloClient<object>
  private httpClient: IHttpClient
  private patientId: string
  private observable?: Observable<FetchResult<PatientDeviceDataPointResponse>>
  private taskQueue: ConcurrentQueue
  private subscriberCount = 0

  constructor(patientId: string, client: ApolloClient<object>, httpClient: IHttpClient) {
    this.client = client
    this.httpClient = httpClient
    this.patientId = patientId
    this.taskQueue = new ConcurrentQueue()
  }

  public async onFetchData(
    deviceId: string,
    metric: string,
    range: DateRangeTimestamp,
    setting: MetricSettings | undefined,
    callback: (data: DeviceDataResult) => void,
  ): Promise<DeviceDataResult[]> {
    if (!range.start) return []
    const start = new Date(range.start)
    const end = range.end ? new Date(range.end) : new Date()

    const buckets = getBuckets(start, end)

    const trendName = setting?.children?.length ? setting.children[0] : trendDataKey
    const { data, trend, uncached } = await this.loadCache(deviceId, metric, buckets, trendName)
    if (data.length || trend.length) {
      callback({ data, trend })
    }

    const promises = uncached.map((bucket) => {
      return this.query(
        {
          patientId: this.patientId,
          deviceId: deviceId,
          metricName: metric,
          start: bucket.value.start,
          stop: bucket.value.end,
          bucketSize: bucket.size,
        },
        setting,
      ).then((result) => {
        callback(result)
        return result
      })
    })

    return Promise.all(promises)
    // const result: number[][] = []
    // for (let i = 0; i < dataPoints.length; i++) {
    //   const data = dataPoints[i]
    //   result.push([data[0] / this.timeScale, data[1]])

    //   if (this.timeScale !== 1 && i < dataPoints.length - 1) {
    //     const minuteInMs = 60000
    //     const next = dataPoints[i + 1]
    //     const gapSize = setting?.gapThresholdMinutes ?? 2
    //     if (gapSize * minuteInMs < next[0] - data[0]) {
    //       result.push([data[0] / this.timeScale + 1, NaN])
    //       result.push([next[0] / this.timeScale - 1, NaN])
    //     }
    //   }
    // }
  }

  public onNewDataPoints(
    deviceId: string,
    metricName: string,
    showTrend: boolean,
    callback: (data: DeviceDataResult) => void,
  ) {
    if (!this.observable) {
      this.observable = this.client.subscribe({
        query: ON_NEW_DEVICE_DATAPOINT_SUBSCRIPTION,
        variables: { patientId: this.patientId },
      })
    }

    this.subscriberCount++
    const subscription = this.observable.subscribe((nextResult) => {
      if (nextResult.data?.newPatientDeviceDataPoints.deviceId !== deviceId) return
      const series = nextResult.data?.newPatientDeviceDataPoints.series ?? []

      const dataPoints = series?.find((x: { name: string }) => x.name === metricName)?.dataPoints ?? []
      const aggregator = getOrCreateAggregator(deviceId, metricName)

      const data: number[][] = []
      const trend: number[][] = []

      if (!showTrend) {
        callback({ data: dataPoints, trend: trend })
        return
      }

      dataPoints.forEach((point) => {
        data.push([point[0], point[1]])
        aggregator.addData(point).forEach((d) => trend.push([d[0], d[1]]))
      })

      callback({ data, trend })
    })

    return () => {
      subscription.unsubscribe()
      this.subscriberCount--
      if (this.subscriberCount < 1) {
        this.observable = undefined
        this.subscriberCount = 0
      }
    }
  }

  private async loadCache(
    deviceId: string,
    metricName: string,
    buckets: TimeBucket[],
    children: string,
  ): Promise<DeviceDataResult & { uncached: TimeBucket[] }> {
    const promises: Promise<number[][] | undefined>[] = []
    const avgPromises: Promise<number[][] | undefined>[] = []
    buckets.forEach((bucket) => {
      promises.push(
        dataCache.load(this.patientId, deviceId, metricName, dataKey, bucket.value.start, bucket.size.toString()),
      )
      avgPromises.push(
        dataCache.load(this.patientId, deviceId, metricName, children, bucket.value.start, bucket.size.toString()),
      )
    })

    const uncached: TimeBucket[] = []
    const cachedData = await Promise.all(promises)
    const cachedTrendData = await Promise.all(avgPromises)
    const data: number[][] = []
    const trend: number[][] = []

    for (let i = 0; i < buckets.length; i++) {
      const d = cachedData[i]
      if (!d) {
        uncached.push(buckets[i])
        continue
      }

      d.forEach((d) => data.push(d))

      const trendData = cachedTrendData[i]
      if (!trendData) continue
      trendData.forEach((d) => trend.push(d))
    }

    return { data, trend, uncached }
  }

  private async query(query: DeviceDataQuery, setting?: MetricSettings): Promise<DeviceDataResult> {
    const { patientId, deviceId, metricName, start, stop, bucketSize } = query

    const { data, buffer } = await this.bufferedQuery(
      { deviceId, metricName, patientId, start, stop, bucketSize },
      setting,
    )

    const isNow = isNowBucket(start, bucketSize)

    if (!isNow) {
      this.saveToCache(dataKey, data, query)
    }

    const trend: number[][] = []

    const noTrend = !setting?.averageWindowSeconds && !setting?.children
    if (noTrend || !data.length) {
      return { data, trend }
    }

    if (setting?.children?.length) {
      const childName = setting.children[0]
      const trendResult = await this.bufferedQuery(
        { deviceId, metricName: childName, patientId, start, stop, bucketSize },
        setting,
      )

      if (!isNow) {
        this.saveToCache(childName, trendResult.data, query)
      }
      return { data, trend: trendResult.data }
    }

    const aggregator = isNow ? getOrCreateAggregator(deviceId, metricName) : createAggregator(metricName)
    if (isNow) {
      aggregator.setSampleSize(getSampleSizeSec(bucketSize))
    }

    buffer.forEach((data) => aggregator.addData(data))
    data.forEach((data) => {
      aggregator.addData(data).forEach((d) => {
        trend.push(d)
      })
    })

    if (!isNow) {
      this.saveToCache(trendDataKey, trend, query)
    }
    return { data, trend }
  }

  private saveToCache(name: string, data: number[][], query: DeviceDataQuery) {
    const { patientId, deviceId, metricName, start, bucketSize } = query
    setTimeout(() => {
      requestAnimationFrame(() => {
        dataCache.save(patientId, deviceId, metricName, start, name, data, bucketSize)
      })
    })
  }

  private async bufferedQuery(
    param: DeviceDataQuery,
    setting?: MetricSettings,
  ): Promise<{ data: number[][]; buffer: number[][] }> {
    const bufferTime = setting?.averageWindowSeconds ?? 0

    const { start } = param
    param.start = subSeconds(start, bufferTime)
    const now = new Date()
    if (now < param.stop && param.start < now) {
      param.stop = now
    }

    // console.log(`query start: ${start} sample: ${param.bucketSize}h`)
    // console.log(`query end: ${param.stop}`)

    const result = await this.queryGQLBinary(param)

    if (!bufferTime) {
      return { data: result, buffer: [] }
    }

    const data: number[][] = []
    const buffer: number[][] = []
    for (let i = 0; i < result.length; i++) {
      if (result[i][0] < start.getTime()) {
        buffer.push(result[i])
      } else {
        data.push(result[i])
      }
    }
    return { data, buffer }
  }

  private async queryGQLBinary(param: DeviceDataQuery): Promise<number[][]> {
    let s = 0
    const task = () => {
      performance.mark('queryStart')
      s = performance.now()
      return this.client.query({
        query: QUERY_DATAPOINTS,
        variables: { ...param, sampleSize: getSampleSize(param.bucketSize) },
        fetchPolicy: 'no-cache',
      })
    }

    const response = await this.taskQueue.enqueue<ApolloQueryResult<any>>(task)
    performance.mark('queryEnd')
    // console.log(param.metricName, (performance.now() - s) / 1000)
    if (response.error) {
      // Todo*
      console.warn(response.error)
    }

    const data: number[][] = decode(base64ToBufferAsync(response.data.deviceData))
    return data
  }
}

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

// Use a lookup table to find the index.
const lookup = typeof Uint8Array === 'undefined' ? [] : new Uint8Array(256)
for (let i = 0; i < chars.length; i++) {
  lookup[chars.charCodeAt(i)] = i
}

const base64ToBufferAsync = (base64: string): ArrayBuffer => {
  const len = base64.length
  let bufferLength = base64.length * 0.75,
    i,
    p = 0,
    encoded1,
    encoded2,
    encoded3,
    encoded4

  if (base64[base64.length - 1] === '=') {
    bufferLength--
    if (base64[base64.length - 2] === '=') {
      bufferLength--
    }
  }

  const arraybuffer = new ArrayBuffer(bufferLength),
    bytes = new Uint8Array(arraybuffer)

  for (i = 0; i < len; i += 4) {
    encoded1 = lookup[base64.charCodeAt(i)]
    encoded2 = lookup[base64.charCodeAt(i + 1)]
    encoded3 = lookup[base64.charCodeAt(i + 2)]
    encoded4 = lookup[base64.charCodeAt(i + 3)]

    bytes[p++] = (encoded1 << 2) | (encoded2 >> 4)
    bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2)
    bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63)
  }

  return arraybuffer
}

export interface IPatientService {
  onFetchData(
    device: string,
    metric: string,
    dateRange: DateRangeTimestamp,
    setting: MetricSettings | undefined,
    callback: (data: DeviceDataResult) => void,
  ): Promise<DeviceDataResult[]>
  onNewDataPoints(
    deviceId: string,
    metricName: string,
    computeTrend: boolean,
    callback: (data: DeviceDataResult) => void,
  ): () => void
}
