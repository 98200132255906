import { useApolloClient } from '@apollo/client'
import { IPatientService, PatientService } from '../services'
import { useHttpClient } from '../infrastructure'

export const usePatientService = (patientId?: string): IPatientService | undefined => {
  const client = useApolloClient()
  const httpClient = useHttpClient()
  if (!patientId || !httpClient) return

  return new PatientService(patientId, client, httpClient)
}
