import * as Sentry from '@sentry/react'
import {
  browserProfilingIntegration,
  moduleMetadataIntegration,
  reactRouterV6BrowserTracingIntegration,
  replayCanvasIntegration,
  replayIntegration,
  contextLinesIntegration,
  extraErrorDataIntegration,
  captureConsoleIntegration,
  feedbackIntegration,
} from '@sentry/react'
import { excludeGraphQLFetch } from 'apollo-link-sentry'
import React from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import { activeThemeName } from '../themes'

export const InitialiseSentry = () => {
  const environment =
    process.env.NODE_ENV === 'production'
      ? window.location.hostname.split('.')[0]
      : import.meta.env.VITE_APP_SENTRY_ENV ?? 'development'
  Sentry.init({
    dsn:
      process.env.NODE_ENV === 'production'
        ? 'https://510fa78e8c85e511077d82022a4995ec@sentry.fpinformatics.net/6'
        : 'https://d0d0233b3a91eb4e4a99ee6366e78106@sentry.fpinformatics.net/3',
    beforeBreadcrumb: excludeGraphQLFetch,
    integrations: [
      browserProfilingIntegration(),
      contextLinesIntegration(),
      extraErrorDataIntegration(),
      captureConsoleIntegration({ levels: ['warn', 'error', 'assert'] }),
      moduleMetadataIntegration(),
      reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      replayCanvasIntegration(),
      feedbackIntegration({
        showBranding: false,
        colorScheme: activeThemeName === 'dark' ? 'dark' : 'light',
        showName: true,
        showEmail: false,
        triggerLabel: '',
        autoInject: false, // Manually injected depending on configuration
      }),
    ],
    // Performance Monitoring
    enableTracing: true,
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^http(s?):\/\/localhost(:\d+)?\//,
      /^https:\/\/.+\.fpinformatics\.net\//,
      /^https:\/\/(?!auth)(.+)\.fpresearch\.net\//,
    ],
    environment,
    release: 'vitaldetect-ui@' + import.meta.env.VITE_APP_VERSION,
    autoSessionTracking: true,
    replaysOnErrorSampleRate: 1.0,
    sampleRate: 1.0,
    profilesSampleRate: 1.0,
    // debug: true,
  })
}
