import { ReactNode, useCallback, useState } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { useUpdatePatientState } from '../../hooks/useUpdatePatientState'
import { Patient, PatientState } from '../../types'
import { toPatientDisplayText } from '../../utils'

export const UpdatePatientModal = ({
  patient,
  mode,
  message,
  onComplete,
  children,
}: {
  patient: Patient
  mode: PatientState
  message?: string
  onComplete?: () => void
  children: ReactNode
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = useCallback(() => {
    setIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  let action = ''
  switch (mode) {
    default:
    case PatientState.ACTIVE:
      action = 'Activate'
      break
    case PatientState.INACTIVE:
      action = 'Deactivate'
      break
  }

  return (
    <>
      <div className="v-iconButton" onClick={openModal}>
        {children}
      </div>
      <Dialog className="v-modal u-noSelect" open={isOpen} onClose={closeModal}>
        <DialogTitle>{action} Patient</DialogTitle>
        <span className="fontIcon v-modal-closeButton" onClick={closeModal}>
          close
        </span>
        <UpdatePatientView
          patient={patient}
          mode={mode}
          message={message}
          label={action}
          onClose={() => {
            closeModal()
            onComplete && onComplete()
          }}
        />
      </Dialog>
    </>
  )
}

export const UpdatePatientView = ({
  patient,
  mode,
  label,
  message,
  onClose,
}: {
  patient: Patient
  mode: PatientState
  label: string
  message?: string
  onClose: () => void
}) => {
  const { updatePatientState, loading, error } = useUpdatePatientState(patient.id, mode)
  const [checked, setChecked] = useState<boolean>(false)

  const handleCheck = useCallback(() => {
    setChecked(!checked)
  }, [checked])

  const handleClick = useCallback(() => {
    updatePatientState().then(() => {
      onClose()
    })
  }, [])

  return (
    <>
      <div className="v-modal-content">
        {error ? <div className="v-modal-warning">{error}</div> : null}
        <DialogContent>
          <Typography>
            {message || `Please confirm you would like to ${label.toLocaleLowerCase()} this patient.`}
          </Typography>
          <FormControlLabel
            control={<Checkbox onClick={handleCheck} checked={checked} />}
            label={`${label} "${toPatientDisplayText(patient)}"`}
          />
        </DialogContent>
      </div>
      <br />
      <div className="v-modal-footer">
        <DialogActions>
          <Button variant="outlined" disabled={loading} disableRipple onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" disabled={loading || !checked} autoFocus disableRipple onClick={handleClick}>
            {loading ? 'Processing...' : label}
          </Button>
        </DialogActions>
      </div>
    </>
  )
}
