import { FC, useCallback, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  usePatients,
  useMetricSettings,
  useFirebaseRoutesAnalytics,
  usePatientFilter,
  usePatientDevices,
} from '../../hooks'
import { Aggregate, Patient } from '../../types'
import { usePatientList } from '../../hooks/usePatientList'
import { Info } from '../common'
import { patientComparer, toMetricDisplayText, toPatientDisplayText } from '../../utils'
import { PatientActionRow } from '.'

export const PatientListPage: FC = () => {
  const [showActive, setActive] = useState<boolean>(true)
  const { wardId } = useParams()
  const { loading, data } = usePatients(parseInt(wardId ?? ''), showActive)
  const patients = usePatientList(data?.map((p) => p.id) ?? [])
  useFirebaseRoutesAnalytics()

  const handleClick = useCallback(
    (value: boolean) => {
      if (value === showActive) return
      setActive(value)
    },
    [showActive],
  )

  if (loading) return <Info text="Loading ..." />

  return (
    <div className="v-contentView v-contentView--largeMargin">
      <div className="v-contentArea">
        <div className="v-fixedWidthWrapper">
          <div className="v-row">
            <div className="v-contentTitle">Patients</div>
            <div className="u-noSelect">
              <span
                className={`v-patientStateButton ${showActive ? '' : 'v-patientStateButton--inactive u-pointer'}`}
                onClick={() => handleClick(true)}
              >
                active
              </span>
              |
              <span
                className={`v-patientStateButton ${showActive ? 'v-patientStateButton--inactive u-pointer' : ''}`}
                onClick={() => handleClick(false)}
              >
                inactive
              </span>
            </div>
          </div>
          {patients.length ? (
            showActive ? (
              <PatientList data={patients} />
            ) : (
              <InactivePatientList data={patients} />
            )
          ) : (
            <Info text="no patient data" />
          )}
        </div>
      </div>
    </div>
  )
}

const PatientList: FC<{ data: Patient[] }> = ({ data }) => {
  const fields = ['respiratoryrate', 'heartrate', 'spO2']
  const patients = usePatientFilter(data).sort(patientComparer)

  return (
    <div>
      <div className="v-row v-row--header">
        <div className="v-row-item--wide">Name / ID</div>
        {fields.map((f) => (
          <div key={f} className={`v-row-item ${f === 'spO2' ? 'v-row-item--short' : null}`}>
            {useMetricSettings()[f]?.displayName}
          </div>
        ))}
      </div>
      {patients.map((patient) => (
        <PatientInfoView key={patient.id} patient={patient} />
      ))}
    </div>
  )
}

export const InactivePatientList: FC<{ data: Patient[] }> = ({ data }) => {
  const patients = usePatientFilter(data).sort(patientComparer)

  return (
    <>
      <div className="v-row v-row--header">
        <div className="v-row-item--wide">Name / ID</div>
      </div>
      {patients.map((patient) => (
        <PatientActionRow key={patient.id} patient={patient} />
      ))}
    </>
  )
}

const PatientInfoView: FC<{ patient: Patient }> = ({ patient }) => {
  const { loading, data } = usePatientDevices(patient.id)
  if (loading) return <div className="v-row v-row--placeholder u-noSelect">Loading ...</div>

  const metricSettings = useMetricSettings()
  const aggregates = data?.devices.flatMap((x) => x.aggregates) || []

  const findAggregate = (name: string): Aggregate | undefined => {
    return aggregates.find((x) => x.name === name) ?? undefined
  }

  const resp = findAggregate('respiratoryrate')
  const hr = findAggregate('heartrate')
  const sp = findAggregate('spO2')

  return (
    <Link className="v-row v-row--patient v-row--clickable" key={patient.id} to={`./${patient.id}`} state={patient}>
      <div className="v-row-item v-row-item--wide">
        <div className="v-row-item--name">{toPatientDisplayText(patient)}</div>
        <div className="v-row-item--id">ID {patient.id}</div>
      </div>
      <div className="v-row-item" key="respiratoryrate">
        <span className="v-row-item--data">{toMetricDisplayText(resp?.value, metricSettings[resp?.name || ''])}</span>
        <span className="v-row-item--unit">{metricSettings[resp?.name || '']?.unit}</span>
      </div>
      <div className="v-row-item" key="heartrate">
        <span className="v-row-item--data">{toMetricDisplayText(hr?.value, metricSettings[hr?.name || ''])}</span>
        <span className="v-row-item--unit">{metricSettings[hr?.name || '']?.unit}</span>
      </div>
      <div className="v-row-item v-row-item--short" key="spO2">
        <span className="v-row-item--data">{toMetricDisplayText(sp?.value, metricSettings[sp?.name || ''])}</span>
        <span className="v-row-item--unit">{metricSettings[sp?.name || '']?.unit}</span>
      </div>
    </Link>
  )
}
