import { gql } from '@apollo/client'
import { GraphQLResult, Patient } from '../types'
import { useBatchQuery } from './apolloHelper'

const QUERY_PATIENT = gql`
  query QueryPatient($patientId: ID!) {
    result: patient(id: $patientId) {
      id
      firstName
      lastName
      reference
    }
  }
`

type PatientQueryResult = {
  patient: Patient
}

export const usePatientList = (patientIds: string[]): Patient[] => {
  const params = patientIds.map((p) => ({ patientId: p }))

  const isChanged = (oldData: PatientQueryResult, newData: PatientQueryResult) => {
    return oldData !== newData
  }

  const response = useBatchQuery<GraphQLResult<PatientQueryResult>>(
    QUERY_PATIENT,
    params,
    (param) => '' + param.patientId,
    (oldVal, newVal) => {
      return oldVal.result && newVal.result ? isChanged(oldVal.result, newVal.result) : true
    },
  )

  return response.filter((item) => item).map((item: any) => item.result)
}
