import React, { FC, ReactElement, useCallback, useState } from 'react'
import { ChartPane, DevicePanel, MetricDisplay, GaugeContainer } from '.'
import { AppState, DeviceViewModel, MetricSettings, Patient } from '../../types'
import { getColor } from '../../themes'
import { IPatientService } from '../../services'
import { useSelector } from 'react-redux'
import { useMetricSettings, useWasmSetting } from '../../hooks'
import { SciChartPane } from '../scichart'

export const VitalSurface: FC<{
  name: string
  patient: Patient
  viewModels: DeviceViewModel[]
  service: IPatientService
}> = ({ name, patient, viewModels, service }) => {
  const showControl = useSelector<AppState, boolean>((state) => state.ui.showControl)
  const metricSettings = useMetricSettings()
  const { wasmChartEnabled } = useWasmSetting()

  return (
    <div className="v-vitalSurface">
      <div className="v-surface-title">{name}</div>
      {viewModels.map((viewModel) => (
        <DevicePanel
          key={viewModel.id}
          patientId={patient.id}
          viewModel={viewModel}
          showControl={showControl}
          colorSelector={() => getColor('--pri-ft-color')} // Todo*
        >
          {({ metric, onRemove, showControl }) => {
            return wasmChartEnabled ? (
              <SciChartPane
                key={metric.name}
                deviceId={viewModel.id}
                metricName={metric.name}
                service={service}
                showControl={showControl}
              >
                {({ onClick }) => (
                  <ChartControl
                    metricSetting={metricSettings[metric.name]}
                    onClick={() => onClick()}
                    onRemove={() => onRemove(metric.name)}
                  >
                    {metricSettings[metric.name]?.zones ? (
                      <GaugeContainer key={metric.name} metric={metric} />
                    ) : (
                      <MetricDisplay key={metric.name} metric={metric} />
                    )}
                  </ChartControl>
                )}
              </SciChartPane>
            ) : (
              <ChartPane
                key={metric.name}
                deviceId={viewModel.id}
                metricName={metric.name}
                service={service}
                showControl={showControl}
              >
                {({ onClick }) => (
                  <ChartControl
                    metricSetting={metricSettings[metric.name]}
                    onClick={() => onClick()}
                    onRemove={() => onRemove(metric.name)}
                  >
                    {metricSettings[metric.name]?.zones ? (
                      <GaugeContainer key={metric.name} metric={metric} />
                    ) : (
                      <MetricDisplay key={metric.name} metric={metric} />
                    )}
                  </ChartControl>
                )}
              </ChartPane>
            )
          }}
        </DevicePanel>
      ))}
    </div>
  )
}

export const ChartControl: FC<{
  metricSetting: MetricSettings
  onClick: () => void
  onRemove: () => void
  children: ReactElement
}> = ({ metricSetting, onClick, onRemove, children }) => {
  const showTrend = !!(metricSetting?.averageWindowSeconds || metricSetting?.children?.length)
  const [buttonActive, setButtonActive] = useState<boolean>(showTrend)

  const handleClick = useCallback(() => {
    setButtonActive(!buttonActive)
    onClick()
  }, [buttonActive])

  return (
    <div className="v-chartControl u-noSelect">
      <div className="v-chartControl-header">
        {showTrend ? (
          <div
            onClick={handleClick}
            className={`${buttonActive ? 'v-chartButton' : 'v-chartButton v-chartButton--faded'} u-noSelect`}
          >
            <span className="v-chartButton-text">Trend</span>
          </div>
        ) : null}
        <div onClick={onRemove} className="v-chartButton u-noSelect">
          <span className="fontIcon-small">minimize</span>
        </div>
      </div>
      {children}
    </div>
  )
}
