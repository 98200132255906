import React, { FC, useEffect, useRef } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import solidGauge from 'highcharts/modules/solid-gauge.js'
import highchartsMore from 'highcharts/highcharts-more'
highchartsMore(Highcharts)
solidGauge(Highcharts)

import { getGaugeChartConfig } from './configs'
import { getColor } from '../../themes'
import { Metric } from '../../types'
import { useMetricSettings } from '../../hooks'
import { toRoundedMetric } from '../../utils'

export const GaugeChart: FC<{
  metric: Metric
}> = ({ metric }) => {
  const metricSettings = useMetricSettings()[metric.name]
  const decimalPlaces = metricSettings?.roundUpDigits ?? 0
  const value = Number(toRoundedMetric(metric.value, decimalPlaces))
  const chartRef = useRef<HighchartsReact.RefObject>(null)
  const optionRef = useRef<any>(
    getGaugeChartConfig({
      defaultValue: value,
      setting: metricSettings,
      backgroundColor: getColor('--chart-bg-color'),
      decimalPlaces,
      fontColor: getColor('--sec-ft-color'),
    }),
  )

  useEffect(() => {
    const chart = chartRef.current?.chart
    const series: any = chart?.series[0]
    if (!series) return
    try {
      series.points[0].update(value, true, true)
    } catch {
      //
    }
  }, [value])

  return (
    <div className="v-gaugeChart">
      <HighchartsReact
        key={metric.name}
        highcharts={Highcharts}
        constructorType={'chart'}
        options={optionRef.current}
        ref={chartRef}
      />
    </div>
  )
}
