import { eachDayOfInterval, startOfDay } from 'date-fns'
import { getKey } from '.'
// import { createStoreInDB, getValue, upsert } from './DataStorage'
// const store = 'cached-data'
// createStoreInDB(store)

const inMemoryCache: Map<string, number[][]> = new Map()
const cacheSizeLimit = 1000

const save = (
  patientId: string,
  device: string,
  metric: string,
  date: Date,
  name: string,
  data: number[][],
  bucketSize: number,
): Promise<void> => {
  const key = getKey(patientId, device, metric, name, date.getTime().toString(), bucketSize.toString())
  // console.log('saving: ' + key + ' ' + new Date(date.getTime()))

  inMemoryCache.set(key, data)
  if (cacheSizeLimit < inMemoryCache.size) {
    for (const item of inMemoryCache) {
      inMemoryCache.delete(item[0])
      if (inMemoryCache.size <= cacheSizeLimit) {
        break
      }
    }
  }
  return Promise.resolve()
  // return upsert(store, key, data)
}

const saveRange = async (
  patientId: string,
  device: string,
  metric: string,
  name: string,
  data: number[][],
): Promise<IDBValidKey> => {
  const today = startOfDay(new Date()).getTime()
  const record: Record<number, number[][]> = {}

  for (let i = 0; i < data.length; i++) {
    const timestamp = startOfDay(new Date(data[i][0])).getTime()
    if (today <= timestamp) break
    if (!record[timestamp]) record[timestamp] = []

    record[timestamp].push(data[i])
  }

  const promises: Promise<IDBValidKey>[] = []
  for (const timestamp in record) {
    const key = getKey(patientId, device, metric, name, timestamp)
    // console.log('saving: ' + key + ' ' + new Date(parseInt(timestamp)))
    inMemoryCache.set(key, record[timestamp])
    // promises.push(upsert(store, key, record[timestamp]))
  }
  return Promise.all(promises)
}

const load = async (
  patientId: string,
  device: string,
  metric: string,
  name: string,
  date: Date,
  label?: string,
): Promise<number[][] | undefined> => {
  const key = getKey(patientId, device, metric, name, date.getTime().toString(), label)
  const data = inMemoryCache.get(key)
  return data

  // data = await getValue<number[][]>(store, key)
  // inMemoryCache.set(key, data)
  // return data
}

export const loadRange = async (
  patientId: string,
  device: string,
  metric: string,
  name: string,
  start: Date,
  end: Date = new Date(),
): Promise<number[][]> => {
  const days = eachDayOfInterval({ start, end })

  const promises = days.map((day) => load(patientId, device, metric, name, day))

  return Promise.all(promises).then((data) => {
    const result: any = data.filter((d) => d)
    return result.flatMap((d: number[][]) => d)
  })
}

export const clear = () => {
  inMemoryCache.clear()
}

export const dataCache = { save, saveRange, load, loadRange, clear: undefined }
