import { ReactNode, useCallback, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { DateTimeValidationError, PickerChangeHandlerContext, TimeField } from '@mui/x-date-pickers'
import { differenceInSeconds, startOfDay } from 'date-fns'
import { loadInt, saveInt } from '../../services'

const customRangeStorageKey = 'custom-range-seconds'
const minRange = 5

export const CustomRangeModal = ({
  children,
  onConfirm,
}: {
  children: ReactNode
  onConfirm: (rangeSeconds: number) => void
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = useCallback(() => {
    setIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <>
      <div style={{ width: '100%' }} onClick={openModal}>
        {children}
      </div>
      <Dialog className="v-modal" open={isOpen} onClose={closeModal}>
        <DialogTitle>Set Custom Time Range</DialogTitle>
        <span className="fontIcon v-modal-closeButton" onClick={closeModal}>
          close
        </span>
        <SetRangeView onConfirm={onConfirm} closeModal={closeModal} />
      </Dialog>
    </>
  )
}

export const SetRangeView = ({
  onConfirm,
  closeModal,
}: {
  onConfirm: (rangeSeconds: number) => void
  closeModal: () => void
}) => {
  const [date, setDate] = useState<Date>(new Date(0, 0, 0, minRange))

  useEffect(() => {
    const customRange = loadInt(customRangeStorageKey)
    if (!customRange) return
    const elapsed = new Date(0, 0, 0, 0)
    elapsed.setSeconds(customRange)
    setDate(elapsed)
  }, [])

  const handleChangeDate = useCallback(
    (value: Date | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
      if (!value) return
      if (context.validationError) {
        value = new Date(0, 0, 0, 0)
      }
      setDate(value)
      saveInt(customRangeStorageKey, toSeconds(value))
    },
    [],
  )

  const handleClick = useCallback(() => {
    closeModal()
    onConfirm(toSeconds(date))
  }, [date])

  return (
    <>
      <div className="v-modal-content">
        <TimeField
          sx={{ width: '45%' }}
          value={date}
          onChange={handleChangeDate}
          label="hh:mm:ss"
          format="HH:mm:ss"
          disableFuture
        />
      </div>
      <br />
      <div className="v-modal-footer">
        <DialogActions>
          <Button variant="outlined" disableRipple onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={toSeconds(date) < minRange}
            autoFocus
            disableRipple
            onClick={handleClick}
          >
            Set
          </Button>
        </DialogActions>
      </div>
    </>
  )
}

const toSeconds = (date: Date) => {
  const dateStart = startOfDay(date)
  return differenceInSeconds(date, dateStart)
}
