import {
  ApolloClient,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
  gql,
  useApolloClient,
  useQuery,
} from '@apollo/client'
import { GraphQLPagedResult, PatientNote } from '../types'
import { differenceInSeconds } from 'date-fns'

const QUERY_NOTES = gql`
  query QueryNotes($patientId: ID!, $begin: DateTime!, $end: DateTime!) {
    result: notes(patientId: $patientId, begin: $begin, end: $end) {
      nodes {
        id
        time
        text
      }
    }
  }
`

const CREATE_NOTE = gql`
  mutation CreateNote($patientId: ID!, $text: String!, $time: DateTime!) {
    createNote(input: { patientId: $patientId, time: $time, text: $text }) {
      note {
        id
        patientId
        time
        text
      }
    }
  }
`

const UPDATE_NOTE = gql`
  mutation UpdateNote($noteId: ID!, $patientId: ID!, $text: String, $time: DateTime) {
    updateNote(input: { noteId: $noteId, patientId: $patientId, text: $text, time: $time }) {
      note {
        id
        patientId
        time
        text
      }
    }
  }
`

const DELETE_NOTE = gql`
  mutation DeleteNote($noteId: ID!, $patientId: ID!) {
    deleteNote(input: { noteId: $noteId, patientId: $patientId }) {
      boolean
    }
  }
`

type NotesQueryResult = {
  loading: boolean
  error?: ApolloError
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<GraphQLPagedResult<PatientNote>>>
  notes: PatientNote[]
}

let queryEnd = new Date()
export const useNotes = (patientId: string, begin: Date, end?: Date): NotesQueryResult => {
  if (!end) {
    // Todo* reduce the number of re-renders o PatientPage so we can remove this logic
    const now = new Date()
    if (differenceInSeconds(now, queryEnd) >= 5) queryEnd = now
  } else {
    queryEnd = end
  }

  const { loading, data, error, refetch } = useQuery<GraphQLPagedResult<PatientNote>>(QUERY_NOTES, {
    variables: { patientId, begin, end: queryEnd },
    fetchPolicy: 'cache-first',
  })

  if (error) {
    console.warn(error)
  }
  return { loading, error, refetch: () => refetch({ end: new Date() }), notes: data?.result.nodes ?? [] }
}

export const useQueryNote = () => {
  const client = useApolloClient()
  return (noteId: string) => {
    return client.cache.readFragment({
      id: client.cache.identify({ __typename: 'Note', id: noteId }),
      fragment: gql`
        fragment SelectedNote on Note {
          id
          text
          time
        }
      `,
    })
  }
}

export const useUpsertNote = () => {
  const client = useApolloClient()

  return (patientId: string, text: string, time: Date, noteId?: string) => {
    return noteId
      ? client.mutate({ mutation: UPDATE_NOTE, variables: { noteId, patientId, text, time } })
      : client.mutate({ mutation: CREATE_NOTE, variables: { patientId, text, time } })
  }
}

export const deleteNote = (client: ApolloClient<object>, patientId: string, noteId: string) => {
  return client.mutate({ mutation: DELETE_NOTE, variables: { noteId, patientId } }).then((response) => {
    if (response.data.deleteNote.boolean) client.cache.evict({ id: `Note:${noteId}` })
  })
}
