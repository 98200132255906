import { lazy } from 'react'

export * from './GaugeContainer'
export * from './InterventionPanel'
// export * from './InterventionSurface'
export * from './DevicePanel'
export * from './MetricDisplay'
export * from './MetricTile'
export * from './SettingsPane'
export * from './VitalSurface'

export const ChartPane = lazy(() => import('./ChartPane'))
export const Navigator = lazy(() => import('./Navigator'))
export const NotePanel = lazy(() => import('./NotePanel'))
export const TimeAxis = lazy(() => import('./TimeAxis'))
