import * as React from 'react'
import { FC } from 'react'

type MenuItem = {
  id: string
  text: string
  onClick?: () => void
}
export const DropdownMenu: FC<{ items: MenuItem[]; trigger: React.ReactElement; styleName?: string }> = ({
  items,
  trigger,
  styleName,
}) => {
  return (
    <div className={`v-dropdown-trigger ${styleName}`}>
      {trigger}
      <div className="v-dropdown-wrapper">
        <ul className="v-dropdown-container">
          {items.map((item) => (
            <li key={item.id} className="v-dropdown-list">
              <div onClick={item.onClick} className="v-dropdown-item">
                {item.text}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
