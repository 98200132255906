import { IAggregator } from './IAggregator'

export default class NoOpAggregator implements IAggregator {
  public addData(data: number[]): number[][] {
    return []
  }

  public getValue(): number {
    return 0
  }

  public reset() {
    //
  }

  public setSampleSize(seconds: number): void {
    //
  }
}
