import { differenceInDays } from 'date-fns'
import { RangeButton } from '../types'
import { msIn2Weeks } from '../Constants'

export const computePeriods = (data: Date[]): { start: Date; end: Date }[] => {
  let start = data[0]
  let end = start
  const output: { start: Date; end: Date }[] = []

  for (let i = 0; i < data.length; i++) {
    const next = data[i]

    const diff = differenceInDays(next, end)
    if (0 < diff && diff === 1) {
      end = next
    } else if (1 < diff) {
      output.push({ start, end })
      start = end = next
    }

    if (i === data.length - 1) {
      output.push({ start, end })
    }
  }

  return output
}

export const toSeconds = (value: number, unit: string) => {
  switch (unit) {
    case 'second':
      return value
    case 'minute':
      return value * 60
    case 'hour':
      return value * 3600
    case 'day':
      return value * 86400
    case 'week':
      return value * 604800
    default:
      return 1
  }
}
export const toMs = (value: number, unit: string) => {
  return toSeconds(value, unit) * 1000
}

export function toRangeIndexLookup(data: RangeButton[]): (input: number) => number {
  const lookup = data.map((d, index) => {
    if (!d.count) {
      return index === 0 ? 0 : msIn2Weeks
    }
    return toMs(d.count, d.type)
  })

  return (input: number) => {
    if (lookup[lookup.length - 1] < input) {
      return lookup.length
    }

    let result = -1

    for (let i = 0; i < lookup.length; i++) {
      if (input <= lookup[i]) {
        result = i
        break
      }
    }
    return result
  }
}

