import { useModulesSettings } from '.'
import { PatientModuleSettings } from '../types'

export const activeChartKey = 'chart-type'

export const useWasmSetting = (): { wasmChartEnabled?: boolean; enableMousePan?: boolean } => {
  const { enableWasm, enableMousePan, enableChartToggle } = useModulesSettings()['patient'] as PatientModuleSettings

  var defaultChart = enableWasm ? 'wasm' : 'js'
  let activeChart = defaultChart
  if (enableChartToggle) {
    activeChart = localStorage.getItem(activeChartKey) ?? defaultChart
  }
  let wasmChartEnabled = activeChart !== 'js'
  return { wasmChartEnabled, enableMousePan }
}
