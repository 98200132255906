import { gql, useApolloClient } from '@apollo/client'
import { ActiveDevice, Metric, UsageSegment, DeviceViewModel, DeviceGroupViewModel, MetricSettings } from '../types'
import { useEffect, useState } from 'react'
import { useDeviceGroupSettings, useDeviceSettings, useMetricSettings } from './useMetaData'

const QUERY_DEVICE = gql`
  query QueryDevice($deviceId: String!) {
    result: device(id: $deviceId) {
      id
      mac
      name
      modelId
      serialNumber
    }
  }
`

export const useDeviceGroups = (
  usageSegments: UsageSegment[],
  activeDevices?: ActiveDevice[],
): DeviceGroupViewModel[] | undefined => {
  const [state, setState] = useState<DeviceGroupViewModel[]>()
  const client = useApolloClient()
  const deviceGroupSettings = useDeviceGroupSettings()
  const deviceSettings = useDeviceSettings()
  const metricSettings = useMetricSettings()

  useEffect(() => {
    const deviceList = mergeDevices(usageSegments, activeDevices || [], metricSettings)
    if (!deviceList.length) {
      setState([])
      return
    }

    const promises = deviceList.map((d) =>
      client.query({
        query: QUERY_DEVICE,
        variables: {
          deviceId: d.id,
        },
        fetchPolicy: 'cache-first',
      }),
    )

    Promise.all(promises)
      .then((response) => response.map((r: any) => r.data.result))
      .then((result) => {
        const groups = toModel(deviceGroupSettings)
        deviceList.forEach((viewModel) => {
          viewModel.device = result.find((d) => d?.id === viewModel.id)
          const settings = deviceSettings[viewModel.device?.modelId || '']
          viewModel.connectable = settings?.connectable
          let groupKey = settings?.group
          if (groupKey === undefined) groupKey = 'vital'
          groups.find((g) => g.id === groupKey)?.data.push(viewModel)
        })
        setState(groups)
      })
      .catch((e) => {
        console.warn(e)
      })
  }, [usageSegments, activeDevices])

  return state
}

const getOrAddDevice = (deviceList: DeviceViewModel[], deviceId: string, isActive = false): DeviceViewModel => {
  let result = deviceList.find((device) => device.id === deviceId)
  if (!result) {
    result = {
      id: deviceId,
      metrics: [],
      segments: [],
      isActive,
    }
    deviceList.push(result)
  }
  return result
}

const updateInfo = (
  device: DeviceViewModel,
  metric: Metric,
  metricSettings: Record<string, MetricSettings>,
): DeviceViewModel => {
  if (metricSettings[metric.name] && metricSettings[metric.name].groupUnder) return device
  if (!device.metrics.find((m) => m.name === metric.name)) {
    device.metrics.push(metric)
  }
  return device
}

const mergeDevices = (
  usageSegments: UsageSegment[],
  activeDevices: ActiveDevice[],
  metricSettings: Record<string, MetricSettings>,
): DeviceViewModel[] => {
  const deviceList: DeviceViewModel[] = []
  for (const device of activeDevices) {
    const viewModel = getOrAddDevice(deviceList, device.id, true)
    for (const aggregate of device.aggregates) {
      updateInfo(viewModel, aggregate, metricSettings)
    }
  }

  for (const segment of usageSegments) {
    const viewModel = getOrAddDevice(deviceList, segment.deviceId)
    updateInfo(viewModel, { name: segment.metric }, metricSettings)
    viewModel.segments.push(segment)
  }
  return deviceList
}

const toModel = (data: any[]): DeviceGroupViewModel[] => {
  return data.map((d) => Object.assign({ data: [] }, d)) ?? []
}
