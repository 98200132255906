import { addDays, addHours, differenceInCalendarDays, differenceInMilliseconds, subMilliseconds } from 'date-fns'
import { TimeBucket } from '../types'
import { UTCDateMini } from '@date-fns/utc'

const msInHours = 3600000

export const getBuckets = (start: Date, end: Date): TimeBucket[] => {
  if (end < start) throw 'start/end dates are out of order'
  const hourDiff = differenceInMilliseconds(end, start) / msInHours
  let startUtc = getBucketStart(start, hourDiff)
  const endUtc = getBucketEnd(subMilliseconds(end, 1), hourDiff)

  const result: TimeBucket[] = []

  if (24 < hourDiff) {
    const dateDiff = differenceInCalendarDays(subMilliseconds(endUtc, 1), startUtc)
    for (let i = 0; i <= dateDiff; i++) {
      result.push({ size: 24, value: { start: addDays(startUtc, i), end: addDays(startUtc, i + 1) } })
    }
    return result
  }

  let size = 0
  if (hourDiff <= 0.5) {
    size = 0.5
  } else if (hourDiff <= 2) {
    size = 2
    // return [{ size: 2, value: { start: startUtc, end: addHours(startUtc, 2) } }]
  } else if (hourDiff <= 12) {
    size = 12
    // return [{ size: 12, value: { start: startUtc, end: addHours(startUtc, 12) } }]
  }
  // else if (hourDiff <=48) {
  //   size = 48
  // }
  else {
    size = 24
  }

  // if (hourDiff <= 24) {
  //   return [{ size: 24, value: { start: startUtc, end: addHours(startUtc, 24) } }]
  // }

  while (startUtc < endUtc) {
    const nextBoundary = size === 24 ? addDays(startUtc, 1) : addHours(startUtc, size)
    result.push({ size, value: { start: startUtc, end: nextBoundary } })
    startUtc = nextBoundary
  }
  return result
}

export const getBucketStart = (date: Date, bucketSize: number) => {
  if (bucketSize <= 0.5) {
    const halfHour = date.getUTCMinutes() < 30 ? 0 : 1
    return new UTCDateMini(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), halfHour * 30),
    )
  }

  if (bucketSize <= 2) {
    let hour = date.getUTCHours()
    hour = hour - (hour % 2)
    return new UTCDateMini(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), hour)
  }

  if (bucketSize <= 12) {
    let hour = date.getUTCHours()
    hour = hour < 12 ? 0 : 12
    return new UTCDateMini(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), hour)
  }

  // if (bucketSize <= 24) {
  //   return new UTCDateMini(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  // }

  return new UTCDateMini(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

export const getBucketEnd = (date: Date, bucketSize: number) => {
  if (bucketSize <= 0.5) {
    if (date.getUTCMinutes() < 30) {
      return new UTCDateMini(
        Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), 30),
      )
    }

    return addHours(
      new UTCDateMini(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours()),
      1,
    )
  }

  if (bucketSize <= 2) {
    let hour = date.getUTCHours()
    hour = hour % 2 ? hour + 1 : hour + 2
    return new UTCDateMini(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), hour)
  }

  if (bucketSize <= 12) {
    const hour = date.getUTCHours()
    if (hour < 12) {
      return new UTCDateMini(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 12)
    }
  }

  return addDays(new UTCDateMini(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()), 1)
}

export const isNowBucket = (date: Date, bucketSize: number): boolean => {
  const nowBucketStart = getBucketStart(new Date(), bucketSize)
  return nowBucketStart.getTime() <= date.getTime()
}

export const getKey = (
  patientId: string,
  device: string,
  metric: string,
  type: string,
  timestamp: string,
  label?: string,
) => {
  let result = `${patientId}:${device}:${metric}:${type}:${timestamp}`
  if (label) result += ':' + label
  return result
}

export const getSampleSizeSec = (hours: number): number => {
  if (hours <= 0.5) return 0
  if (hours <= 2) return 4
  if (hours <= 12) return 16
  if (hours <= 24) return 60
  return 300
}

export const getSampleSize = (diff: number): string | undefined => {
  if (diff <= 0.5) return
  if (diff <= 2) return '4s'
  if (diff <= 12) return '16s'
  if (diff <= 24) return '1m'
  return '5m'
}
