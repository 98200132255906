export const decode = (input: ArrayBuffer): number[][] => {
  const result: number[][] = []
  const dv = new DataView(input)
  if (dv.byteLength === 0) return []
  const buffer = new Uint8Array(4)

  buffer.set(new Uint8Array(input, 0, 4))
  let data = dv.getFloat32(0, true)

  const timestamp = dv.getBigInt64(4, true)
  let currentTimestamp = Number(timestamp)
  result.push([currentTimestamp, data])

  let offset = 12
  while (offset < input.byteLength) {
    buffer.set(new Uint8Array(input, offset, 4))
    data = dv.getFloat32(offset, true)
    offset += 4

    const { value, consumed } = readVarUint(new Uint8Array(input, offset), 0)
    currentTimestamp = currentTimestamp + value
    result.push([currentTimestamp, data])
    offset += consumed
  }

  return result
}

export const readVarUint = (buffer: Uint8Array, offset = 0) => {
  let byte: number
  let curr = offset
  let val = 0
  let shift = 0

  do {
    byte = buffer[curr++]
    val |= (byte & 0x7f) << shift
    shift += 7
  } while (byte & 0x80)

  if (shift <= 35) {
    return { value: val >>> 0, consumed: curr - offset }
  } else {
    throw new Error('Parsing failed')
  }
}
