import { IAggregator } from './IAggregator'

export default class AverageAggregator implements IAggregator {
  private sum = 0
  private count = 0
  private interval = 0
  private recentData: number[][] = []
  private sampleSize = 0

  constructor(interval: number) {
    this.interval = interval
  }

  setSampleSize(seconds: number): void {
    this.sampleSize = seconds * 1000
  }

  public addData(data: number[]): number[][] {
    if (!this.interval) return []

    const dataTime = data[0]
    if (this.recentData.length) {
      if (dataTime <= this.recentData[0][0]) {
        this.reset()
      } else if (this.sampleSize && dataTime - this.recentData[this.recentData.length - 1][0] < this.sampleSize) {
        return []
      }
    }

    const value = data[1]
    if (isNaN(value)) return [[dataTime, NaN]]

    const start = dataTime - this.interval

    let itemsToRemove = 0
    for (let i = 0; i < this.recentData.length; i++) {
      const d = this.recentData[i]
      if (d[0] < start) {
        itemsToRemove = i + 1
        this.sum -= d[1]
        this.count--
      } else {
        break
      }
    }

    this.recentData.splice(0, itemsToRemove)

    this.recentData.push(data)
    this.sum += value
    this.count++

    return [[dataTime, this.getValue()]]
  }

  public getValue(): number {
    if (this.count <= 0) return 0
    return this.sum / this.count
  }

  public reset() {
    this.sum = 0
    this.count = 0
    this.recentData = []
  }
}
