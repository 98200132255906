/* eslint-disable quotes */
import { FirebaseApp, getApp, initializeApp } from 'firebase/app'
import { FC, ReactElement, createContext, useEffect } from 'react'
import { getAnalytics, setUserProperties } from 'firebase/analytics'
import { getThemeName } from '../themes'

const devNames = ['localhost', 'dev', 'staging', 'test']

const firebaseConfig = {
  apiKey: 'AIzaSyA67rfMGgTpsVDjfsoHv2flreNovW8pRNc',
  authDomain: 'vitaldetect.firebaseapp.com',
  projectId: 'vitaldetect',
  storageBucket: 'vitaldetect.appspot.com',
  messagingSenderId: '925714943027',
  appId: '1:925714943027:web:979f80b6d6dabb6c00e746',
  measurementId: 'G-FVG9GFVY0L',
}

const firebaseDevConfig = {
  apiKey: "AIzaSyBWD8AhMXseni14EEtxEWZjN7yZouvaPQA",
  authDomain: "vitaldetect-dev.firebaseapp.com",
  projectId: "vitaldetect-dev",
  storageBucket: 'vitaldetect-dev.appspot.com',
  messagingSenderId: "1028061591291",
  appId: "1:1028061591291:web:1d21cc7168b49ae399225e",
  measurementId: "G-B93LZGY9H5",
}

export const FirebaseAppContext = createContext<FirebaseApp | undefined>(undefined)

export const FirebaseAppProvider: FC<{ children: ReactElement }> = ({ children }) => {
  let app: FirebaseApp | undefined = undefined
  try {
    app = getApp()
  }
  catch (e) { /* empty */ }

  useEffect(() => {
    const isDev = devNames.some(n => window.location.hostname.includes(n))
    app = initializeApp(isDev ? firebaseDevConfig : firebaseConfig)

    const analytics = getAnalytics(app)
    setUserProperties(analytics, { 'theme': getThemeName() })
  }, [])

  return <FirebaseAppContext.Provider value={app}>{children}</FirebaseAppContext.Provider>
}
