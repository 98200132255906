import { addMinutes, format, subHours, subMinutes } from 'date-fns'
import React, { FC } from 'react'
import { useGatewayInfo } from '../../hooks/useGatewayInfo'
import { OnlineRecord } from '../../types'
import { roundToHalfHour } from '../../utils'
import { useFirebaseRoutesAnalytics } from '../../hooks'

export const SystemPage = () => {
  useFirebaseRoutesAnalytics()

  const gateways = useGatewayInfo().sort((a, b) => a.name.localeCompare(b.name, 'en-US', { sensitivity: 'case' }))

  return (
    <div className="v-dashboard">
      <div className="v-dashboard-header">
        <div className="v-dashboard-title">System Status</div>
      </div>
      <table className="v-table">
        <colgroup>
          <col span={1} style={{ width: '20%' }} className="v-table-column" />
          <col span={1} style={{ width: '20%' }} className="v-table-column" />
          <col span={1} style={{ width: '20%' }} className="v-table-column" />
          <col span={1} style={{ width: '20%' }} className="v-table-column" />
          <col span={1} style={{ width: '5%' }} className="v-table-column" />
        </colgroup>
        <tbody>
          <tr>
            <th>Hub</th>
            <th>Connection</th>
            <th>Status</th>
            <th>Timeline</th>
          </tr>
          {gateways.map((gateway) => (
            <tr className="v-table-row" key={gateway.name}>
              <td className="v-table-cell">
                <span className="v-table-icon fontIcon">iswGsm</span>
                {gateway.name}
              </td>
              <td className="v-table-cell">{gateway.uplinkType}</td>
              <td className="v-table-cell">{gateway.status}</td>
              <td className="v-table-cell u-noSelect">
                <IndicatorList records={gateway.onlineRecords} />
              </td>
              {/* <td>
                <span className="v-table-link u-noSelect fontIcon">iswActionLogs</span>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const IndicatorList: FC<{
  records: OnlineRecord[]
}> = ({ records }) => {
  const now = new Date()
  const currentHalfHour = roundToHalfHour(now)
  const totalBuckets = 16
  const periodStart = subHours(currentHalfHour, totalBuckets / 2)
  const buckets: number[] = []

  for (let i = 1; i <= totalBuckets; i++) {
    const start = addMinutes(periodStart, i * 30)
    if (isOnline(records, start, addMinutes(start, 30))) {
      buckets.push(1)
    } else {
      buckets.push(0)
    }
  }

  return <>{buckets.map((value, index) => getIndicator(buckets.length - 1 - index, value === 1))}</>
}

const getIndicator = (index: number, connected: boolean) => {
  const cls = connected ? 'v-bar v-bar--green' : 'v-bar v-bar--red'

  return (
    <span data-tooltip={getTimeLabel(index)} className={cls} key={index}>
      &nbsp;
    </span>
  )
}

const isOnline = (records: OnlineRecord[], from: Date, to: Date) => {
  for (const record of records) {
    if (!record.stop || record.stop > from) {
      return record.start < to
    }
  }
  return false
}

const getTimeLabel = (index: number) => {
  const period = subMinutes(roundToHalfHour(new Date()), index * 30)
  return format(period, 'h:mmaaa')
}
