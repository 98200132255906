import React, { FC, Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { Info, NoteModal } from '../common'
import { NotePanel } from '.'
import { AppState, DataRange, Patient, PatientNote } from '../../types'
import { uiSlice } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { INoteService } from '../../services'
import { noOp } from '../../utils'
import { SciNotePanel } from '../scichart'
import { useWasmSetting } from '../../hooks'

export const NoteSurface: FC<{
  patient: Patient
  service: INoteService
}> = ({ patient, service }) => {
  const dataRange = useSelector<AppState, DataRange>((state) => state.ui.dataRange)
  const [noteEvents, setNoteEvents] = useState<string[]>([])
  const dispatch = useDispatch()
  const unsubscribeNoteRef = useRef<() => void>(noOp)
  const { wasmChartEnabled } = useWasmSetting()

  const openModal = useCallback(
    (patientId: string, note?: PatientNote) => {
      dispatch(uiSlice.actions.showNoteModal({ open: true, patientId, note }))
    },
    [patient.id],
  )

  useEffect(() => {
    const range = {
      start: dataRange.value.start ? new Date(dataRange.value.start) : undefined,
      end: dataRange.value.end ? new Date(dataRange.value.end) : undefined,
    }
    service.query(range).then((notes) => {
      notes.forEach((note) => {
        if (note.type === 'UserNote' || noteEvents.findIndex((n) => n === note.type) !== -1) return
        noteEvents.push(note.type)
        setNoteEvents([...noteEvents])
      })
    })
  }, [patient.id, noteEvents, dataRange])

  useEffect(() => {
    unsubscribeNoteRef.current = service.onNoteEvent((note) => {
      if (!note.type || note.type === 'UserNote' || noteEvents.findIndex((n) => n === note.type) !== -1) return
      setNoteEvents([...noteEvents, note.type])
    })
    return () => unsubscribeNoteRef.current()
  }, [noteEvents])

  return (
    <>
      <NoteModal />
      <div className="v-noteSurface-title">Notes</div>
      <Suspense fallback={<Info text="Loading ..." />}>
        {wasmChartEnabled ? (
          <SciNotePanel
            name="Note"
            noteType="UserNote"
            patientId={patient.id}
            service={service}
            onClick={openModal}
            editable={true}
            showControl={true}
          />
        ) : (
          <NotePanel
            name="Note"
            noteType="UserNote"
            patientId={patient.id}
            service={service}
            onClick={openModal}
            editable={true}
            showControl={true}
          />
        )}
      </Suspense>
      {noteEvents.length ? (
        <>
          <div className="v-noteSurface-title">Events</div>
          <Suspense fallback={<Info text="Loading ..." />}>
            {noteEvents.map((n) =>
              wasmChartEnabled ? (
                <SciNotePanel
                  noteType={n}
                  patientId={patient.id}
                  service={service}
                  onClick={openModal}
                  showControl={true}
                  key={n}
                />
              ) : (
                <NotePanel
                  noteType={n}
                  key={n}
                  patientId={patient.id}
                  service={service}
                  onClick={openModal}
                  showControl={true}
                />
              ),
            )}
          </Suspense>
        </>
      ) : undefined}
    </>
  )
}
