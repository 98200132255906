import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { GatewayInfo, GraphQLResult } from '../types'

const QUERY_GATEWAY_INFO = gql`
  query QueryGatewayInfo {
    result: cassiaGatewayInfo {
      name
      uplinkType
      status
      onlineRecords {
        start
        stop
      }
    }
  }
`

export const useGatewayInfo = (): GatewayInfo[] => {
  const { loading, data, error } = useQuery<GraphQLResult<GatewayInfo[]>>(QUERY_GATEWAY_INFO, {
    fetchPolicy: 'no-cache',
  })
  if (!loading && !error && data && data.result) {
    const output = data.result
    return map(output)
  }
  if (error) {
    console.warn(error)
  }
  return []
}

const map = (data: any[]) => {
  return data?.map((d) => {
    const mappedRecords = d.onlineRecords
      .map((record: any) => ({
        start: new Date(record.start),
        stop: record.stop ? new Date(record.stop) : null,
      }))
      .sort((a: any, b: any) => a.start - b.start)
    return { ...d, onlineRecords: mappedRecords }
  })
}
