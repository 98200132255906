import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'
import { PatientState, UpdatePatientStatePayload } from '../types'

const UPDATE_PATIENT_MUTATION = gql`
  mutation UpdatePatientState($patientId: ID, $state: PatientState!) {
    updatePatientState(input: { patientId: $patientId, state: $state }) {
      patient {
        id
        firstName
        lastName
        reference
      }
    }
  }
`

export const useUpdatePatientState = (patientId: string, state: PatientState) => {
  const [error, setError] = useState<string>()
  const [updatePatientState, { loading }] = useMutation(UPDATE_PATIENT_MUTATION, {
    variables: {
      patientId,
      state,
    },
    onCompleted: (data: { updatePatientState: UpdatePatientStatePayload }) => {
      const success = data.updatePatientState.patient
      if (!success) {
        setError(`Failed to update patient to ${state}`)
      }
    },
    onError: (error) => {
      setError(error.message)
    },
  })

  return { updatePatientState, loading, error }
}
