import { FC } from 'react'
import { usePatients, useFirebaseRoutesAnalytics, usePatientFilter } from '../../hooks'
import { Patient, PatientState } from '../../types'
import { usePatientList } from '../../hooks/usePatientList'
import { UpdatePatientModal, Info, PatientSearchInput } from '../common'
import { patientComparer, toPatientDisplayText } from '../../utils'
import { Link, useParams } from 'react-router-dom'
import { DeletePatientModal } from '../common/DeletePatientModal'

export const PatientListView: FC<{ showActive: boolean; onClose: () => void }> = ({ showActive, onClose }) => {
  const { wardId } = useParams()
  const { loading, data } = usePatients(parseInt(wardId ?? ''), showActive)
  const patients = usePatientList(data?.map((p) => p.id) ?? [])
  useFirebaseRoutesAnalytics()

  if (loading)
    return (
      <div className="v-drawer">
        <Info text="Loading ..." />
      </div>
    )

  return <PatientActionList data={patients} showActive={showActive} onClose={onClose} />
}

const PatientActionList: FC<{ data: Patient[]; showActive: boolean; onClose: () => void }> = ({
  data,
  showActive,
  onClose,
}) => {
  const patients = usePatientFilter(data).sort(patientComparer)

  return (
    <div className="v-drawer">
      <div className="v-drawer-action">
        <span className="fontIcon v-drawer-closeButton" onClick={onClose}>
          close
        </span>
      </div>
      <div className="v-drawer-header u-noSelect">
        <div className="v-row">
          <div className="v-contentTitle">{showActive ? 'Patients' : 'Inactive Patients'}</div>
          <PatientSearchInput />
        </div>
      </div>
      <div className="v-row v-row--header v-drawer-row">
        <div className="v-row-item--wide">Name / ID</div>
      </div>
      <div className="v-drawer-content">
        {patients.length ? (
          patients.map((patient) => <PatientActionRow key={patient.id} patient={patient} />)
        ) : (
          <div className="v-row v-row--placeholder u-noSelect">no inactive patients</div>
        )}
      </div>
    </div>
  )
}

export const PatientActionRow: FC<{ patient: Patient }> = ({ patient }) => {
  return (
    <div className="v-row v-row--patient v-row--clickable">
      <Link
        key={patient.id}
        to={`../Patients/${patient.id}`}
        state={patient}
        className="v-row-item v-row-item--fill v-row-item--link"
      >
        <div className="v-row-item--name">{toPatientDisplayText(patient)}</div>
        <div className="v-row-item--id">ID {patient.id}</div>
      </Link>
      <div className="v-row-item--last u-noSelect">
        <span className="v-row-button" key="activate">
          <UpdatePatientModal
            message="Please confirm you would like to activate this patient, this will add them to the dashboard view."
            patient={patient}
            mode={PatientState.ACTIVE}
          >
            activate
          </UpdatePatientModal>
        </span>
        <span className="v-row-button" key="delete">
          <DeletePatientModal patient={patient} />
        </span>
      </div>
    </div>
  )
}
