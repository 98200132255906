import { useCallback, useState } from 'react'
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { useUpdatePatientState } from '../../hooks/useUpdatePatientState'
import { toPatientDisplayText } from '../../utils'
import { Patient, PatientState } from '../../types'

export const DeletePatientModal = ({ patient }: { patient: Patient }) => {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = useCallback(() => {
    setIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <>
      <div className="v-iconButton" onClick={openModal}>
        delete&nbsp;
        <span className="fontIcon">trash</span>
      </div>
      <Dialog className="v-modal u-noSelect" open={isOpen} onClose={closeModal}>
        <DialogTitle>Delete Patient</DialogTitle>
        <span className="fontIcon v-modal-closeButton" onClick={closeModal}>
          close
        </span>
        <DeletePatientView patient={patient} onClose={closeModal} />
      </Dialog>
    </>
  )
}

export const DeletePatientView = ({ patient, onClose }: { patient: Patient; onClose: () => void }) => {
  const { updatePatientState, loading, error } = useUpdatePatientState(patient.id, PatientState.ARCHIVED)
  const [checked, setChecked] = useState<boolean>(false)

  const handleCheck = useCallback(() => {
    setChecked(!checked)
  }, [checked])

  const handleClick = useCallback(() => {
    updatePatientState().then(() => {
      onClose()
    })
  }, [])

  const label = `Delete "${toPatientDisplayText(patient)}"`

  return (
    <>
      <div className="v-modal-content">
        {error ? <div className="v-modal-warning">{error}</div> : null}
        <Alert severity="warning">Warning: this action cannot be undone.</Alert>
        <DialogContent>
          <Typography>Please confirm you would like to delete this patient.</Typography>
          <FormControlLabel control={<Checkbox onClick={handleCheck} checked={checked} />} label={label} />
        </DialogContent>
      </div>
      <br />
      <div className="v-modal-footer">
        <DialogActions>
          <Button variant="outlined" disabled={loading} disableRipple onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="warning"
            disabled={loading || !checked}
            autoFocus
            disableRipple
            onClick={handleClick}
          >
            {loading ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </div>
    </>
  )
}
