import { endOfHour, isEqual, isSameHour, startOfHour } from 'date-fns'
import { IAggregator } from './IAggregator'

export default class HourlyAggregator implements IAggregator {
  private sum = 0
  private recentData: number[][] = []
  private bucket: Date = new Date()

  public addData(data: number[]): number[][] {
    const dataTime = data[0]
    if (isNaN(data[1])) return [[dataTime, NaN]]
    const dataStartHour = startOfHour(dataTime)
    if (this.recentData.length && dataTime <= this.recentData[0][0]) {
      this.reset()
    }

    if (isSameHour(dataStartHour, this.bucket)) {
      if (this.recentData.length) {
        const diff = data[1] - this.recentData[this.recentData.length - 1][1]
        if (diff > 0) {
          this.sum += diff
        }
        this.recentData.push(data)
      }
      return []
    }

    this.bucket = dataStartHour
    const result = this.collectValues()
    this.recentData = [data]
    this.sum = 0
    return result
  }

  public getValue(): number {
    if (!this.recentData.length) return 0
    return this.sum
  }

  public reset() {
    this.sum = 0
    this.recentData = []
    this.bucket = new Date()
  }

  public setSampleSize(seconds: number): void {
    //
  }

  private collectValues() {
    let result = this.recentData.map((d) => [d[0], this.getValue()])
    if (result.length) {
      const firstTimeValue = result[0][0]
      if (!isEqual(new Date(firstTimeValue), startOfHour(firstTimeValue))) {
        result = [[startOfHour(firstTimeValue).getTime(), this.getValue()], ...result]
      }

      const lastTimeValue = result[result.length - 1][0]
      if (!isEqual(new Date(lastTimeValue), endOfHour(lastTimeValue))) {
        result.push([endOfHour(lastTimeValue).getTime(), this.getValue()])
      }
    }
    return result
  }
}
