import { FC, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { TimeAxis } from '..'
import { IPatientService } from '../../services'
import { AppState, RangeChartData } from '../../types'
// import { InterventionSurface } from './InterventionSurface'

const InterventionView: FC<{ service: IPatientService; showControl: boolean }> = ({ service, showControl }) => {
  const [data, setData] = useState<RangeChartData[]>([])
  const [selected, setSelected] = useState<RangeChartData[]>([]) // Todo* rename RangeChartData

  useEffect(() => {
    // service.fetchDevices().then((result) => {
    //   if (result.length < 1) return
    //   setData(toChartModel(result))
    // })
  }, [])

  // Todo* remove
  const addPlot = useCallback(
    (id: string) => {
      const item = data.find((d) => d.id === id)
      if (!item) return

      setSelected(() => [...selected, item])
      setData(() => data.filter((d) => d.id !== id))
    },
    [data, selected],
  )

  const removePlot = useCallback(
    (name: string) => {
      const index = selected.findIndex((x) => x.name === name)
      if (index < 0) return

      const item = selected.splice(index, 1)
      setSelected(() => [...selected])
      setData(() => [...data, ...item])
    },
    [data, selected],
  )

  return (
    <div className="v-layout v-layout--vertical v-interventionPanel">
      <div className="v-panel-title">Interventions</div>
      <TimeAxis id="intervention-time-axis" showControl={showControl} />
      {data.map(
        (d) =>
          // <InterventionSurface key={d.name} onClick={addPlot} data={d} service={service} showControl={showControl} />
          null,
      )}
    </div>
  )
}

// // Todo* move this logic further down
// const toChartModel = (source: Device[]): RangeChartData[] => {
//   const data: RangeChartData[] = []

//   source.forEach((d) => {
//     const result: RangeChartData = {
//       id: d.name,
//       name: d.name,
//       value: [],
//     }

//     d.usage?.forEach((range) => {
//       result.value.push({
//         y: 0,
//         x: range.start,
//         x2: range.end,
//       })
//     })

//     data.push(result)
//   })

//   return data
// }

const mapStateToProps = (state: AppState, ownProps: { service: IPatientService }) => ({
  showControl: state.ui.showControl,
  service: ownProps.service,
})

export const InterventionPanel = connect(mapStateToProps)(InterventionView)
