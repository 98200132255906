import { ReactElement, useCallback, useRef, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { noOp } from '../../utils'

export const ConfirmModal = ({
  children,
}: {
  children: (
    openConfirmModal: ({ message, onConfirm }: { message: string; onConfirm: () => void }) => void,
    closeConfirmModal: () => void,
  ) => ReactElement
}): ReactElement => {
  const [message, setMessage] = useState<string>('')
  const confirmCallbackRef = useRef<() => void>(noOp)

  const openModal = useCallback(({ message, onConfirm }: { message: string; onConfirm: () => void }) => {
    setMessage(message)
    confirmCallbackRef.current = onConfirm
  }, [])

  const closeModal = useCallback(() => {
    setMessage('')
  }, [])

  const handleConfirm = useCallback(() => {
    confirmCallbackRef.current()
    closeModal()
  }, [])

  return (
    <>
      <Dialog className="v-modal" open={message.length > 0} onClose={closeModal}>
        <DialogTitle>Confirm</DialogTitle>
        <span className="fontIcon v-modal-closeButton u-noSelect" onClick={closeModal}>
          close
        </span>
        <div className="v-modal-content">
          <DialogContent>
            <Typography>{message}</Typography>
          </DialogContent>
        </div>
        <div className="v-modal-footer">
          <DialogActions>
            <div>
              <Button variant="outlined" disableRipple onClick={closeModal}>
                Cancel
              </Button>
              &nbsp; &nbsp;
              <Button variant="contained" autoFocus disableRipple onClick={handleConfirm}>
                Confirm
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
      {children(openModal, closeModal)}
    </>
  )
}
