type ConcurrentTask<T> = {
  task: () => Promise<T>
  resolve: (value: T | PromiseLike<T>) => void
  reject: (reason?: any) => void
}

export class ConcurrentQueue {
  maxConcurrentTasks: number
  tasksInProgress = 0
  taskQueue: ConcurrentTask<any>[] = []

  constructor(maxConcurrentTasks = 1) {
    this.maxConcurrentTasks = maxConcurrentTasks
  }

  public enqueue<T>(task: () => Promise<T>) {
    const promise = new Promise<T>((resolve, reject) => {
      this.taskQueue.push({ task, resolve, reject })
      this.tasksInProgress++

      if (this.tasksInProgress <= this.maxConcurrentTasks) {
        this.dequeue<T>()
      }
    })
    return promise
  }

  async dequeue<T>(): Promise<T | undefined> {
    const item = this.taskQueue.shift()
    if (!item) return
    const { task, resolve, reject } = item
    task()
      .then((result) => resolve(result))
      .catch((reason) => reject(reason))
      .finally(() => {
        this.tasksInProgress--
        if (this.taskQueue.length) {
          this.dequeue<T>()
        }
      })
  }
}
