import React from 'react'
import { useCallback, useState } from 'react'
import { sendExportRequest } from '../../services/FileDownloadService'
import { Button, DialogActions, DialogContent, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { ExportInfo, Patient } from '../../types'
import { addDays, differenceInDays, subDays } from 'date-fns'
import { useAnalytics } from '../../hooks/useAnalytics'
import { logEvent } from 'firebase/analytics'
import { useFirstSessionDate } from '../../hooks'
import { IHttpClient } from '../../infrastructure'

export const ExportPanel = ({
  patient,
  httpClient,
  loadExportInfo,
  setWarning,
  closeModal,
}: {
  patient: Patient
  httpClient: IHttpClient
  loadExportInfo: () => Promise<ExportInfo[] | void>
  setWarning: (message: string) => void
  closeModal: () => void
}) => {
  const [start, setStart] = useState<Date>(new Date())
  const [end, setEnd] = useState<Date>(new Date())
  const [sampleRate, setSampleRate] = useState<number>(60)
  const [isLoading, setIsLoading] = useState(false)
  const [sampleStep, setSampleStep] = useState<number>(1)
  const firstSession = useFirstSessionDate(patient.id)
  const analytics = useAnalytics()

  const changeSampleStep = useCallback((s: Date, e: Date, sample: number) => {
    const diff = differenceInDays(e, s)
    const step = 7 < diff ? 60 : 1
    setSampleStep(step)
    if (sample > step) return
    diff > 7 && sample <= step ? setSampleRate(900) : setSampleRate(step)
    return
  }, [])

  const handleClose = useCallback(() => {
    closeModal()
  }, [])

  const handleChangeStart = useCallback(
    (value: Date | null) => {
      if (!value || end < value) {
        return
      }
      setStart(value)
      changeSampleStep(value, end, sampleRate)
      setWarning('')
    },
    [start, end, sampleRate],
  )

  const handleChangeEnd = useCallback(
    (value: Date | null) => {
      if (!value || value < start) {
        return
      }
      setEnd(value)
      changeSampleStep(start, value, sampleRate)
      setWarning('')
    },
    [start, end, sampleRate],
  )

  const handleChangeSample = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(event.target.value)
      setSampleRate(value)
    },
    [sampleRate],
  )

  const handleExport = useCallback(() => {
    if (sampleRate <= 0) return
    setIsLoading(true)
    sendExportRequest({ patients: [patient.id], start, stop: end, sampleRate: sampleRate * 1000 }, httpClient)
      .then((fileName) => {
        if (!fileName) {
          setWarning('Selected period has no data')
          setIsLoading(false)
          return
        }
        logEvent(analytics, 'export_activity', {
          action: 'start',
          start_range: start,
          end_range: end,
          sample_rate: sampleRate,
        })

        loadExportInfo().finally(() => {
          setIsLoading(false)
        })
      })
      .catch((reason) => {
        setWarning(reason)
        setIsLoading(false)
      })
  }, [patient.id, start, end, sampleRate])

  return (
    <>
      <div className="v-modal-content">
        <DialogContent>
          <div className="v-modal-subtitle">New Export</div>
          <div className="v-modal-datepicker">
            <DatePicker
              label="Start Date"
              value={start}
              minDate={firstSession ?? subDays(end, 31)}
              maxDate={end}
              onAccept={handleChangeStart}
            />
            &nbsp; &nbsp; &nbsp;
            <DatePicker
              label="End Date"
              value={end}
              minDate={start}
              maxDate={new Date() < addDays(start, 31) ? new Date() : addDays(start, 31)}
              onAccept={handleChangeEnd}
            />
          </div>
          <TextField
            // variant="standard"
            label="Sample Rate (seconds)"
            type="number"
            value={sampleRate}
            error={sampleRate < sampleStep}
            inputProps={{
              step: sampleStep,
              min: sampleStep,
            }}
            helperText={sampleRate >= sampleStep ? null : 'Invalid value'}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangeSample}
            style={{ width: '200px' }}
          />
        </DialogContent>
      </div>
      <div className="v-modal-footer">
        <DialogActions>
          <Button variant="outlined" disabled={isLoading} disableRipple onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" disabled={isLoading} autoFocus disableRipple onClick={handleExport}>
            Export
            {isLoading ? <span style={{ marginLeft: 8 }} className="v-spinner--small" /> : null}
          </Button>
        </DialogActions>
      </div>
    </>
  )
}
