import { Patient, PatientViewModel } from '../types'

export const patientComparer = (a: Patient | PatientViewModel, b: Patient | PatientViewModel) => {
  return `${a.lastName}${a.firstName}${a.reference}`.localeCompare(
    `${b.lastName}${b.firstName}${b.reference}`,
    'en-US',
    { sensitivity: 'case' },
  )
}

export const toPatientDisplayText = (patient: Patient) => {
  const { firstName, lastName, reference } = patient
  let name = lastName ?? ''
  if (firstName) {
    if (name) {
      name += ', ' + firstName
    } else {
      name = firstName
    }
  }
  if (!name && reference) {
    name = reference
  }
  return name
}
