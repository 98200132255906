import { gql, useQuery } from '@apollo/client'
import { Patient, GraphQLResult, PatientResponse } from '../types'

const QUERY_PATIENT = gql`
  query QueryPatient($patientId: ID!) {
    result: patient(id: $patientId) {
      id
      firstName
      lastName
      reference
      state
    }
  }
`

export const usePatient = (patientId: string): Patient => {
  const query = QUERY_PATIENT
  const { loading, data, error } = useQuery<GraphQLResult<PatientResponse>>(query, {
    variables: { patientId },
  })
  if (!loading && !error && data && data.result) {
    return data.result
  }

  if (error) {
    console.warn(error)
  }
  return {
    id: patientId,
    firstName: loading ? 'loading...' : 'error!!!',
    lastName: '',
  }
}
