import { FetchResult, gql, useApolloClient, ApolloCache } from '@apollo/client'
import { useEffect } from 'react'
import {
  Aggregate,
  ActiveDevice,
  PatientDeviceDataEvent,
  GraphQLResult,
} from '../types'
import { updatePatientStatus } from '../services'

export const SUBSCRIBE_PATIENT_DEVICE_DATA_EVENT = gql`
  subscription PatientDeviceDataEvents($groupId: Int!) {
    result: patientDeviceDataEvents(groupId: $groupId) {
      deviceId
      patientId
      updated
      deviceData {
        key
        value
      }
    }
  }
`

const updateActivePatientAggregates = (
  devices: ActiveDevice[],
  evt: PatientDeviceDataEvent,
  updatePatientStatus: (patientId: string, newStatus: number) => void,
) => {
  const { patientId, deviceId, deviceData, updated } = evt
  devices = devices.slice(0)
  const cacheIndex = devices.findIndex((device) => device.id === deviceId)
  if (cacheIndex < 0) {
    return devices
  }
  const oldDevices = devices[cacheIndex]
  const device = { ...oldDevices }
  devices[cacheIndex] = device
  const updatedAggregates = deviceData.map<Aggregate>(({ key, value }) => ({ name: key, value, updated }))
  device.aggregates = oldDevices.aggregates
    .map((oldAggregate) => {
      const update = deviceData.find((update) => oldAggregate.name === update.key)
      const result = update ? { name: update.key, value: update.value, updated } : oldAggregate
      notifyEwsZoneChange(result, oldAggregate, patientId, updatePatientStatus)
      return result
    })
    .concat(
      updatedAggregates.filter((aggregate) => !oldDevices.aggregates.find((value) => value.name === aggregate.name)),
    )
  return devices
}

const notifyEwsZoneChange = (
  newData: Aggregate,
  old: Aggregate,
  patientId: string,
  updatePatientStatus: (patientId: string, newStatus: number) => void,
) => {
  if (newData.name !== 'ews') return
  if (Math.ceil(parseFloat(newData.value) / 10) - Math.ceil(parseFloat(old.value) / 10) <= 0) return
  // Patient moved up EWS category - move card to top
  updatePatientStatus(patientId, 0)
}

const handleEvent = (
  groupId: number,
  cache: ApolloCache<object>,
  result: FetchResult<GraphQLResult<PatientDeviceDataEvent>>,
): void => {
  if (!result.data) {
    return
  }

  const event = result.data.result
  if (!event) {
    return
  }
  cache.modify({
    id: 'PatientDevices:' + event.patientId,
    fields: {
      devices: (devices) =>
        updateActivePatientAggregates(devices, event, (patientId: string, newStatus: number) => {
          updatePatientStatus(cache, patientId, newStatus)
        }),
    },
  })
}

export const usePatientDeviceDataSubscription = (groupId: number) => {
  const client = useApolloClient()
  useEffect(() => {
    const observable = client.subscribe<GraphQLResult<PatientDeviceDataEvent>>({
      query: SUBSCRIBE_PATIENT_DEVICE_DATA_EVENT,
      variables: { groupId },
    })
    const subscription = observable.subscribe((next) => handleEvent(groupId, client.cache, next))
    return () => {
      subscription.unsubscribe()
    }
  }, [groupId])
}
