import { differenceInMilliseconds, parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { Metric, PatientModuleSettings } from '../types'
import { useModulesSettings } from './useMetaData'

export const useTimerState = (data: Metric): string => {
  const patientModuleSettings = useModulesSettings()['patient'] as PatientModuleSettings
  const [state, setState] = useState<string>('inactive')
  const now = new Date()

  useEffect(() => {
    if (!data.updated) {
      setState('inactive')
      return
    }
    const diff = differenceInMilliseconds(now, parseISO(data.updated))
    const timeToIdle = patientModuleSettings.idleMinutes * 60000 - diff
    const timeToInactive = patientModuleSettings.inactiveMinutes * 60000 - diff
    if (timeToIdle > 0) setState('default')

    const idleTimer = setTimeout(() => {
      setState('idle')
    }, timeToIdle)

    const inactiveTimer = setTimeout(() => {
      setState('inactive')
    }, timeToInactive)

    return () => {
      clearTimeout(idleTimer)
      clearTimeout(inactiveTimer)
    }
  }, [data])

  return state
}
