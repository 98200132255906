import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useAuthToken, useHttpClient } from '../../infrastructure'
import { getExportInfo } from '../../services/FileDownloadService'
import { Dialog, DialogTitle } from '@mui/material'
import { ExportInfo, Patient } from '../../types'
import { ProgressPanel } from './ProgressPanel'
import { ExportPanel } from './ExportPanel'
import { ConfirmModal } from './ConfirmModal'
import { Info } from './Info'

export const ExportModal = ({ patient }: { patient: Patient }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [warning, setWarningState] = useState<string>('')
  const [workItems, setWorkItems] = useState<ExportInfo[]>([])
  const client = useHttpClient()

  useEffect(() => {
    if (!isOpen) return
    loadExportInfo()
  }, [isOpen])

  const loadExportInfo = useCallback(() => {
    if (!client) return Promise.resolve([])
    return getExportInfo({ patientId: patient.id }, client)
      .then((data) => {
        setWorkItems(data)
        return data
      })
      .catch(({ message }) => {
        setWarning(message)
      })
  }, [client])

  const openModal = useCallback(() => {
    setIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setWarning('')
    setIsOpen(false)
  }, [])

  const setWarning = useCallback((message: string) => {
    setWarningState(message)
  }, [])

  const reset = useCallback(() => {
    setWarning('')
    setWorkItems([])
  }, [])

  if (!client) return <Info text="... Loading" />

  return (
    <ConfirmModal>
      {(openConfirmModal) => {
        return (
          <>
            <div className="v-iconButton" onClick={openModal}>
              Export&nbsp;
              <span className="fontIcon">shareIos</span>
            </div>
            <Dialog className="v-modal" open={isOpen} onClose={closeModal}>
              <DialogTitle>Export Patient Data</DialogTitle>
              <span className="fontIcon v-modal-closeButton" onClick={closeModal}>
                close
              </span>
              <div className="v-modal-content">{warning ? <div className="v-modal-warning">{warning}</div> : null}</div>
              {workItems.length ? (
                <ProgressPanel
                  items={workItems}
                  httpClient={client}
                  loadExportInfo={loadExportInfo}
                  openConfirmModal={openConfirmModal}
                  closeModal={closeModal}
                  reset={reset}
                  setWarning={setWarning}
                />
              ) : (
                <ExportPanel
                  patient={patient}
                  httpClient={client}
                  loadExportInfo={loadExportInfo}
                  closeModal={closeModal}
                  setWarning={setWarning}
                />
              )}
            </Dialog>
          </>
        )
      }}
    </ConfirmModal>
  )
}
