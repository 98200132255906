import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { StyledSelect } from './common/StyledSelect'
import { useAuthToken } from '../infrastructure/TokenContext'
import jwt_decode from 'jwt-decode'
import { useInstanceSetting } from '../infrastructure'
import { PatientSearchInput } from './common'

export const TopBar = () => {
  const { logout } = useAuth0()
  const url = useInstanceSetting()?.instanceSettingUrl || ''
  const [options, setOptions] = useState<{ value: string; label: string }[]>([])

  const bodyStyle = getComputedStyle(document.body)
  const bgColor = bodyStyle.getPropertyValue('--pri-bg-color')
  const ftColor = bodyStyle.getPropertyValue('--pri-ft-color')
  const currentInstance = window.location.hostname

  const token = jwt_decode<{ vdOrganizations: string[] }>(useAuthToken())

  useEffect(() => {
    fetch(url, { cache: 'no-cache' })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        const options = Object.entries(json)
          .filter((pair) => token.vdOrganizations.includes(pair[1] as string))
          .map((pair) => {
            const name = pair[0].split('.')[0]
            return { value: pair[0], label: name.charAt(0).toUpperCase() + name.slice(1) }
          })

        setOptions(options)
      })
  }, [])

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  return (
    <div className="v-topbar">
      {options.length !== 0 ? (
        <StyledSelect
          className="v-wardSelector"
          style={{ color: ftColor, backgroundColor: bgColor }}
          options={options}
          defaultIndex={options.findIndex((value) => value.value === currentInstance)}
          onChange={(value) =>
            value?.value && value.value !== currentInstance && (window.location.href = 'https://' + value.value)
          }
        />
      ) : (
        <></>
      )}

      <div className="fillWidth" />
      {/* <span className="v-actionLink" onClick={onAddPatient}>
        {' '}
        + Add a patient{' '}
      </span> */}
      <PatientSearchInput />
      <span className="v-actionLink v-actionLink--plain" onClick={handleLogout}>
        Log out
      </span>
    </div>
  )
}
