import { IAggregator } from './IAggregator'
import { useMetricSettings } from '../hooks'
import NoOpAggregator from './NoOpAggregator'
import AverageAggregator from './AverageAggregator'
import HourlyAggregator from './HourlyAggregator'

const aggregators: Record<string, IAggregator> = {}

export const getOrCreateAggregator = (deviceId: string, metric: string): IAggregator => {
  const key = `${deviceId}_${metric}`
  const setting = useMetricSettings()[metric]

  if (!setting?.children && !setting?.averageWindowSeconds) return new NoOpAggregator()

  if (!aggregators[key]) {
    aggregators[key] = createAggregator(metric)
  }

  return aggregators[key]
}

export const createAggregator = (metric: string): IAggregator => {
  const setting = useMetricSettings()[metric]

  if (setting?.children) {
    return new HourlyAggregator()
  }

  const interval = (setting?.averageWindowSeconds ?? 0) * 1000
  if (!interval) return new NoOpAggregator()

  return new AverageAggregator(interval)
}
