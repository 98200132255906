import { useApolloClient } from '@apollo/client'
import { uiStateService, updatePatientStatus } from '../services'
import { ActivePatientInfo, PatientDevices, PatientDevicesQueryResult } from '../types'
import { useBatchQuery } from './apolloHelper'
import { usePatientList } from './usePatientList'
import { QUERY_PATIENT_DEVICE } from './usePatientDevices'

export const useActivePatientInfo = (patientIds: string[]) => {
  const patients = usePatientList(patientIds)
  const patientDevices = batchQueryPatientDevices(patientIds)

  const result: ActivePatientInfo[] = []
  patients.forEach((p, i) => {
    result.push({
      id: p.id,
      firstName: p.firstName,
      lastName: p.lastName,
      reference: p.reference,
      status: patientDevices[i]?.status ?? 0,
      devices: patientDevices[i]?.devices ?? [],
    })
  })
  return result
}

export const batchQueryPatientDevices = (patientIds: string[]) => {
  const params = patientIds.map((p) => ({ patientId: p }))
  const client = useApolloClient()

  const isChanged = (oldData: PatientDevices, newData: PatientDevices) => {
    if (oldData === newData) return false
    const cachedStatus = uiStateService.loadPatientStatus(newData.id)
    if (newData.status !== cachedStatus) {
      updatePatientStatus(client.cache, newData.id, cachedStatus)
    }
    return true
  }

  const result = useBatchQuery<PatientDevicesQueryResult>(
    QUERY_PATIENT_DEVICE,
    params,
    (param) => '' + param.patientId,
    (oldVal, newVal) => {
      return oldVal.result && newVal.result ? isChanged(oldVal.result, newVal.result) : true
    },
  )

  return result.map((r) => r?.result)
}
