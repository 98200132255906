import { UTCDateMini } from '@date-fns/utc'
import { ActiveWindow, IGraphQLType, MetricSettings } from '.'

export type Device = {
  id: string
  modelId: string
  mac: string
  name: string
  serialNumber: string
  status?: DeviceStatus
}

export type DeviceStatus = {
  id: string
  isConnected?: boolean
}

export type Aggregate = {
  name: string
  value: string
  updated: string
}

export type ActiveDevice = {
  id: string
  aggregates: Aggregate[]
}

export type PatientDevices = {
  id: string
  status: number
  devices: ActiveDevice[]
}

export type ActivePatientInfo = {
  id: string
  status: number
  devices: ActiveDevice[]
  firstName: string
  lastName: string
  reference?: string
}

export type Group = {
  id: number
  patientIds: string[]
}

export type UsageSegment = {
  deviceId: string
  metric: string
  begin: Date
  end: Date
}

export type ColorStyle = {
  color: string
}

export type DataPoint = {
  timestamp: string
  value: number
}

export type DataPointSeries = {
  name: string
  dataPoints: number[][]
}

export type DeviceDataSet = {
  series: DataPointSeries[]
  deviceId: string
  patientId: string
}

export type DeviceGroup = {
  id: string
  name: string
  order: number
}

export type DeviceGroupViewModel = {
  id: string
  name: string
  data: DeviceViewModel[]
  order: number
}

export type DeviceViewModel = {
  id: string
  device?: Device
  metrics: Metric[]
  segments: UsageSegment[]
  isActive: boolean
  connectable?: boolean
  order?: number
}

export type DeviceDataQuery = {
  patientId: string
  deviceId: string
  metricName: string
  start: Date
  stop: Date
  bucketSize: number
}

export type DeviceDataResult = {
  data: number[][]
  trend: number[][]
}

export type DataExportRequest = {
  patients: string[]
  start: Date
  stop: Date
  sampleRate: number
}

export type FileDownloadRequest = {
  patientId: string
  file: string
}

export type FileRecordRequest = {
  patientId: string
}

export type ExportInfo = {
  patientId: string
  file: string
  status: ExportStatus
  sampleRate: number
  start: Date
  end: Date
  created: Date
}

export enum ExportStatus {
  InProgress = 0,
  Completed = 1,
  Failed = 2,
}

export type GaugeChartOptions = {
  defaultValue?: number
  height?: number
  backgroundColor: string
  decimalPlaces: number
  fontColor?: string
  setting: MetricSettings
}

export type PatientResponse = {
  id: string
  firstName: string
  lastName: string
  reference?: string
  firstSessionDate?: string
}

export type Patient = IGraphQLType & {
  id: string
  firstName: string
  lastName: string
  dob?: Date
  reference?: string
  state?: PatientState
}

export enum PatientState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export type PatientMap = {
  [key: string]: Patient
}

export type UpdatePatientStatePayload = IGraphQLType & {
  patient?: Patient
}

export type PatientNote = {
  id: string
  text: string
  time: string
  type: string
}

export type PatientViewModel = {
  id: string
  ews?: string
  status?: number
  firstName: string
  lastName: string
  reference?: string
  devices: ActiveDevice[]
}

export type Metric = {
  name: string
  value?: string
  updated?: string
}

export type Note = {
  id: string
  title: string
  content: string
}

export type NoteModalState = {
  patientId?: string
  note?: PatientNote
}

export type TimestampRange = {
  start: number
  end: number
}

export type WardObj = {
  id: string
  name: string
  patients: PatientMap
}

export type WardMap = {
  [key: string]: WardObj
}

export type AppState = {
  ui: UIState
}

export type UIState = {
  showControl: boolean
  displayMode: DisplayMode
  noteModal: NoteModalState
  activeWindow: ActiveWindow
  dataRange: DataRange
  patientFilter: string
}

export type DataRange = {
  value: DateRangeTimestamp
  trigger?: string
}

export type DateRange = {
  start?: Date
  end?: Date
}

export type UtcDateRange = {
  start: UTCDateMini
  end: UTCDateMini
}

export type TimeBucket = {
  size: number // hour
  value: UtcDateRange
}

export type DateRangeTimestamp = {
  start?: number
  end?: number
}

export type DisplayMode = {
  count: number
  text: string
  type: string
  kind?: string
}

export type AssemblyVersion = {
  name: string
  version: string
}

export type PlatformInfo = {
  base: AssemblyVersion
  modules: AssemblyVersion[]
  deviceDrivers: AssemblyVersion[]
  notificationHandlers: AssemblyVersion[]
}

export type GatewayInfo = {
  name: string
  uplinkType: string
  status: string
  onlineRecords: OnlineRecord[]
}

export type OnlineRecord = {
  start: Date
  stop: Date | null
}
