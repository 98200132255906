import React, { FC, useCallback } from 'react'
import { DropdownMenu } from '../common'
import { Metric } from '../../types'
import { capitalize, toCapitalizedPhrase, toHexColor, toMetricColor, toMetricDisplayText } from '../../utils'
import { useTimerState, useMetricSettings } from '../../hooks'
import { inactiveSymbol, tileColorOpacity } from '../../Constants'

export const MetricTile: FC<{
  metric: Metric
  onAdd: (id: string) => void
  colorSelector: (id: string) => string
}> = ({ metric, onAdd }) => {
  const state = useTimerState(metric)
  const { name, value } = metric
  const settings = useMetricSettings()[metric.name]
  const color = toMetricColor(value, settings)
  const text = settings?.hideValue ? capitalize(color || '') : toMetricDisplayText(value, settings)
  const fontSize = settings?.categories?.length ? settings.displayFontSize || '26px' : '35px'
  const getValueClass = useCallback(() => {
    let result = 'v-metricTile-value'
    if (state === 'idle' || state === 'inactive') result += ' u-faded-opacity'
    return result
  }, [state, text])

  const getStyle = useCallback(() => {
    const style: any = { fontSize }
    if (state === 'inactive' || !color || !settings || !settings.hideValue) return style
    const background = toHexColor(color) + tileColorOpacity
    style.background = background
    return style
  }, [value, settings, color, state])

  return (
    <div className="v-metricTile u-noSelect">
      <div style={getStyle()} className="v-metricTile-display">
        <div className="v-metricTile-name">{settings?.displayName ?? toCapitalizedPhrase(name)}</div>
        <div className={getValueClass()}>{state === 'inactive' ? inactiveSymbol : text}</div>
        <div className="v-metricTile-unit">{settings?.unit}</div>
      </div>
      <div className="v-metricTile-control">
        <DropdownMenu
          trigger={<div>...</div>}
          items={[
            {
              id: '1',
              text: 'Create Chart',
              onClick: () => {
                onAdd(name)
              },
            },
            // { id: '2', text: 'Add To Chart' },
          ]}
          styleName="v-metricTile-topButton"
        />
        {/* <div onClick={() => onAdd(metric.id)} className="v-metricTile-topButton">
          ...
        </div> */}
        <div
          className="v-metricTile-bottomButton"
          onClick={() => {
            onAdd(name)
          }}
        >
          <span className="fontIcon-small">maximize</span>
        </div>
      </div>
    </div>
  )
}
