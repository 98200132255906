import React, { FC } from 'react'
import Highcharts from 'highcharts/highstock'
import solidGauge from 'highcharts/modules/solid-gauge.js'
import highchartsMore from 'highcharts/highcharts-more'
import { Metric } from '../../types'
import { GaugeChart } from './GaugeChart'
import { useMetricSettings, useTimerState } from '../../hooks'
import { MetricDisplay } from './MetricDisplay'
highchartsMore(Highcharts)
solidGauge(Highcharts)

export const GaugeContainer: FC<{
  metric: Metric
}> = ({ metric }) => {
  const state = useTimerState(metric)
  const metricSettings = useMetricSettings()[metric.name]

  return (
    <>
      {state === 'inactive' || metricSettings.hideValue ? (
        <MetricDisplay metric={metric} />
      ) : (
        <div className={state === 'idle' ? 'u-faded-opacity' : undefined}>
          <GaugeChart metric={metric} />
        </div>
      )}
    </>
  )
}
