import React, { FC, ReactElement, useEffect, useState } from 'react'
import { InstanceSettingContext } from '.'

import { Info } from '../components'
import { InstanceSetting } from '../types'

export const InstanceSettingProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [instanceSetting, setInstanceSetting] = useState<InstanceSetting>()

  useEffect(() => {
    fetch('/api/v1/setting/instance', { cache: 'no-cache' })
      .then((response) => {
        return response.json()
      })
      .then((value) => setInstanceSetting(value))
  }, [])

  if (!instanceSetting) return <Info text="Loading ... " />
  return <InstanceSettingContext.Provider value={instanceSetting}>{children}</InstanceSettingContext.Provider>
}
