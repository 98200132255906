import React, { FC, ReactElement } from 'react'
import { HttpClientContext, IHttpClient, useAuthToken } from '.'

class HttpClient implements IHttpClient {
  private token: string

  constructor(token: string) {
    this.token = token
  }

  public async get(url: string): Promise<Response> {
    const response = await fetch(url, {
      method: 'GET',
      headers: this.getHeader(this.token),
    })
    if (!response.ok) {
      throw 'Network response: ' + response.status
    }
    return response
  }
  
  public async delete(url: string): Promise<Response> {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: this.getHeader(this.token),
    })
    if (!response.ok) {
      throw 'Network response: ' + response.status
    }
    return response
  }
  
  public async post(url: string, data: any): Promise<Response> {
    const response = await fetch(url, {
      method: 'POST',
      headers: this.getHeader(this.token),
      body: JSON.stringify(data),
    })
    if (!response.ok) {
      throw 'Network response: ' + response.status
    }
    return response
  }
  
  private getHeader(token: string) {
    return {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: `Bearer ${token}`,
    }
  }
}

export const HttpClientProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const token = useAuthToken()
  const client = new HttpClient(token)

  return <HttpClientContext.Provider value={client}>{children}</HttpClientContext.Provider>
}
