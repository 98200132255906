import { ApolloClient, FetchResult, Observable, gql } from '@apollo/client'
import { differenceInSeconds, isEqual } from 'date-fns'
import { DateRange, PatientNote } from '../types'
import { ConcurrentQueue } from '.'

const QUERY_NOTES = gql`
  query QueryNotes($patientId: ID!, $begin: DateTime!, $end: DateTime!, $last: Int) {
    result: notes(patientId: $patientId, begin: $begin, end: $end, last: $last) {
      nodes {
        id
        time
        text
        type
      }
    }
  }
`

const SUBSCRIBE_NOTE_EVENT = gql(`subscription NoteChangeEvents($patientId: ID!) {
  noteChangeEvents(patientId: $patientId) {
    id
    patientId
    content {
        time
        text
        type
    }
  }
}`)

type NoteResponse = {
  noteChangeEvents: NoteChangeEvent
}

type NoteChangeEvent = {
  id: string
  patientId: string
  content: {
    time: string
    text: string
    type: string
  }
}

export class NoteService implements INoteService {
  private client: ApolloClient<object>
  private patientId: string
  private observable?: Observable<FetchResult<NoteResponse>>
  private cache: { start: Date; end: Date; data: PatientNote[] } = { start: new Date(), end: new Date(), data: [] }
  private taskQueue: ConcurrentQueue

  constructor(patientId: string, client: ApolloClient<object>) {
    this.client = client
    this.patientId = patientId
    this.taskQueue = new ConcurrentQueue()
  }

  public async query(range: DateRange, type?: string): Promise<PatientNote[]> {
    const { start } = range
    if (!start) return Promise.resolve([])
    const end = range.end ?? new Date()

    const task = async () => {
      if (isEqual(start, this.cache.start) && differenceInSeconds(end, this.cache.end) <= 5) {
        return this.getCache(type)
      }

      const response = await this.client.query({
        query: QUERY_NOTES,
        variables: {
          patientId: this.patientId,
          begin: start,
          end,
          last: 1000,
        },
      })
      if (response.error) {
        throw response.error
      }
      this.cache.start = start
      this.cache.end = end
      this.cache.data = response.data.result.nodes ?? []
      return this.getCache(type)
    }

    try {
      const data = await this.taskQueue.enqueue<PatientNote[]>(task)
      return data
    } catch (ex) {
      console.warn(ex)
      return []
    }
  }

  private getCache(type?: string) {
    if (!type) return this.cache.data
    return this.cache.data.filter((c) => c.type === type)
  }

  public onNoteEvent(callback: (data: PatientNote) => void, type?: string) {
    if (!this.observable) {
      this.observable = this.client.subscribe({
        query: SUBSCRIBE_NOTE_EVENT,
        variables: { patientId: this.patientId },
      })
    }

    const subscription = this.observable.subscribe((nextResult) => {
      const note = nextResult.data?.noteChangeEvents
      if (!note || note.patientId !== this.patientId) return

      const newNote = {
        id: note.id,
        ...note.content,
      }
      if (note.content) {
        this.updateApolloCache(this.client, newNote)
      }

      if (!type || !newNote.type || newNote.type === type) callback(newNote)
    })

    return () => {
      subscription.unsubscribe()
    }
  }

  updateApolloCache(client: ApolloClient<object>, note: { id: string; time: string; text: string; type: string }) {
    client.cache.writeFragment({
      fragment: gql`
        fragment UpdateNote on Note {
          id
          text
          time
          type
        }
      `,
      data: {
        ...note,
        __typename: 'Note',
      },
    })
  }
}

export interface INoteService {
  query(range: DateRange, type?: string): Promise<PatientNote[]>
  onNoteEvent(callback: (data: PatientNote) => void, type?: string): () => void
}
