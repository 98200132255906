import React, { FC, useCallback } from 'react'
import { Metric } from '../../types'
import { capitalize, toCapitalizedPhrase, toHexColor, toMetricColor, toMetricDisplayText } from '../../utils'
import { useTimerState, useMetricSettings } from '../../hooks'
import { inactiveSymbol, tileColorOpacity } from '../../Constants'

export const MetricDisplay: FC<{
  metric: Metric
}> = ({ metric }) => {
  const { value, name } = metric
  const state = useTimerState(metric)
  const settings = useMetricSettings()[name]
  const color = toMetricColor(value, settings)
  const text = settings?.hideValue ? capitalize(color || '') : toMetricDisplayText(value, settings)

  const getStyle = useCallback(() => {
    if (state === 'inactive' || !color || !settings || !settings.hideValue) return
    const background = toHexColor(color) + tileColorOpacity
    return { background, borderRadius: '3px' }
  }, [value, settings, color, state])

  const valueStyle: Record<string, string> = {}
  if (settings?.categories?.length) {
    valueStyle.fontSize = '26px'
  }

  return (
    <div style={getStyle()} className="v-metricDisplay">
      <div className="v-metricDisplay-title">{settings?.displayName ?? toCapitalizedPhrase(metric.name)}</div>
      <div className={`v-metricDisplay-content ${state === 'idle' || state === 'inactive' ? 'u-faded-opacity' : ''}`}>
        <div className="v-metricDisplay-value" style={valueStyle}>
          {state === 'inactive' ? inactiveSymbol : text}
        </div>
        <div className="v-metricDisplay-unit">{settings?.unit}</div>
      </div>
    </div>
  )
}
