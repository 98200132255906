import { useSelector } from 'react-redux'
import { AppState } from '../types'

export const usePatientFilter = <T>(patients: T[]): T[] => {
  const filter = useSelector<AppState, string>((state) => state.ui.patientFilter).toLocaleLowerCase()
  if (!filter || !patients.length) return patients

  return patients.filter((p: any) =>
    !p.firstName && !p.lastName
      ? p.reference?.toLocaleLowerCase().startsWith(filter)
      : p.firstName?.toLocaleLowerCase().startsWith(filter) || p.lastName?.toLocaleLowerCase().startsWith(filter),
  )
}
