import { startOfDay, endOfDay } from 'date-fns'
import { saveAs } from 'file-saver'
import { DataExportRequest, ExportInfo, FileDownloadRequest, FileRecordRequest } from '../types'
import { IHttpClient } from '../infrastructure'

export const sendExportRequest = (data: DataExportRequest, client: IHttpClient): Promise<string> => {
  data.start = startOfDay(data.start)
  data.stop = endOfDay(data.stop)

  return client
    .post('/api/v1/export', data)
    .then((response) => {
      return response.text()
    })
    .then((text) => {
      return text
    })
}

export const getExportInfo = (data: FileRecordRequest, client: IHttpClient): Promise<ExportInfo[]> => {
  return client
    .get(`/api/v1/export/${data.patientId}`)
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      return data.map((d: any) => ({
        patientId: d.patientId,
        file: d.file,
        status: d.status,
        sampleRate: d.sampleRate,
        start: new Date(d.start),
        end: new Date(d.end),
        created: new Date(d.created),
      }))
    })
}

export const deleteExport = (patientId: string, client: IHttpClient): Promise<Response> => {
  return client.delete(`/api/v1/export/${patientId}`)
}

export const downloadZip = async (data: FileDownloadRequest, client: IHttpClient): Promise<number> => {
  const response = await client.post('/api/v1/export/download', data)
  const blob = await response.blob()
  if (!blob.size) {
    return 0
  }
  saveAs(blob, `${new Date().toISOString()}.zip`)
  return 1
}
