import { AppState } from '@auth0/auth0-react'
import { FC, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uiSlice } from '../../store'

export const PatientSearchInput: FC = () => {
  const dispatch = useDispatch()

  const filterValue = useSelector<AppState, string>((state) => state.ui.patientFilter)
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch(uiSlice.actions.setFilter(event.target.value))
  }

  return (
    <input
      value={filterValue}
      className="v-searchInput"
      type={'search'}
      placeholder={'Search'}
      onChange={handleChange}
    />
  )
}
