import { ApolloClient, DocumentNode, useApolloClient } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'

type QueryState<T> = {
  init: boolean
  listQueryResult: (T | null)[]
  version: number
  resultCb: (result: (T | null)[]) => void
  promise?: Promise<void>
  watcherCancellers?: (() => void)[]
}

const batchQuery = <T>(
  client: ApolloClient<object>,
  query: DocumentNode,
  params: Record<string, string | number>[],
  queryState: QueryState<T>,
  isChanged?: (oldVal: T, newVal: T) => boolean,
) => {
  const version = ++queryState.version
  let needQuery = false
  const listQueryResult = params.map<T | null>((param) => {
    const queryResult = client.cache.readQuery<T>({
      query,
      variables: param,
      canonizeResults: true,
    })
    if (queryResult) {
      return queryResult
    }
    needQuery = true
    return null
  })
  const setupWatchers =
    isChanged && params.length > 0
      ? () => {
        return params.map((param, index) => {
          return client.cache.watch<T>({
            query,
            variables: param,
            canonizeResults: true,
            callback: (diff) => {
              const oldVal = queryState.listQueryResult[index]
              const newVal = diff?.result || null
              if (oldVal === newVal) {
                return
              }
              queryState.listQueryResult[index] = newVal
              if (oldVal && newVal && !isChanged(oldVal, newVal)) {
                return
              }
              const listResult = [...queryState.listQueryResult]
              queryState.listQueryResult = listResult
              queryState.resultCb(listResult)
            },
            optimistic: true,
          })
        })
      }
      : null
  if (needQuery) {
    queryState.promise = (queryState.promise || Promise.resolve()).then(() => {
      const promises = listQueryResult.map((item, index) => {
        return (
          item ||
          client
            .query<T>({ query, variables: params[index], canonizeResults: true })
            .then((queryResult) => queryResult.data)
        )
      })
      return Promise.all(promises).then((listResult) => {
        if (version === queryState.version && queryState.resultCb) {
          queryState.listQueryResult = listResult
          setupWatchers && setupWatchers()
          queryState.resultCb(listResult)
        }
      })
    })
  } else {
    queryState.listQueryResult = listQueryResult
    setupWatchers && setupWatchers()
  }
  return listQueryResult
}

export const useBatchQuery = <T>(
  query: DocumentNode,
  params: Record<string, string | number>[],
  paramToKey: (param: Record<string, string | number>) => string,
  isChanged?: (oldVal: T, newVal: T) => boolean,
): (T | null)[] => {
  const client = useApolloClient()
  const queryState = useRef<QueryState<T>>({ init: true, listQueryResult: [], version: 0, resultCb: () => undefined })
  const [listQueryResult, setQueryResult] = useState(() => {
    return batchQuery(client, query, params, queryState.current, isChanged) || []
  })
  queryState.current.resultCb = setQueryResult
  const dep = params.map(paramToKey).join(',')
  useEffect(() => {
    if (queryState.current.init) {
      queryState.current.init = false
      return
    }
    const result = batchQuery(client, query, params, queryState.current, isChanged)
    setQueryResult(result)
    return () => {
      if (queryState.current.watcherCancellers) {
        queryState.current.watcherCancellers.forEach((canceller) => canceller())
        delete queryState.current.watcherCancellers
      }
    }
  }, [dep])
  return listQueryResult
}
