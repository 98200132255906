import { useQuery, gql } from '@apollo/client'
import { PatientState, GraphQLPagedResult, Patient } from '../types'

export const QUERY_PATIENTS = gql`
  query queryPatients($groupId: Int, $state: PatientState) {
    result: patients(orgId: 0, wardId: $groupId, state: $state, first: 100) {
      nodes {
        id
        firstName
        lastName
        reference
      }
    }
  }
`

export const usePatients = (groupId: number, queryActive: boolean = true) => {
  const state = queryActive ? PatientState.ACTIVE : PatientState.INACTIVE
  const { loading, data, error } = useQuery<GraphQLPagedResult<Patient>>(QUERY_PATIENTS, {
    variables: { groupId: groupId, state },
  })

  const result = data?.result?.nodes
  return { loading, data: result, error }
}
