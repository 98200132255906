import { Auth0Provider } from '@auth0/auth0-react'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Info } from '../components'
import { useInstanceSetting } from './InstanceSettingContext'
import { useAnalytics } from '../hooks/useAnalytics'
import { setUserProperties } from 'firebase/analytics'

export const AuthProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [orgId, setOrgId] = useState<string | null>(null)
  const instanceSetting = useInstanceSetting()
  const analytics = useAnalytics()

  useEffect(() => {
    if (!instanceSetting) return

    fetch(instanceSetting.instanceSettingUrl, { cache: 'no-cache' })
      .then((response) => {
        return response.json()
      })
      .then((responseJson) => {
        const orgId = responseJson[window.location.hostname]
        setOrgId(orgId)
        setUserProperties(analytics, { organizationId: orgId })
      })
  }, [])

  if (!instanceSetting || orgId === null) {
    return <Info text="Loading ..." />
  }

  const { domain, audience, clientId } = instanceSetting
  return (
    <Auth0Provider
      domain={domain.replace(/\/$/, '')}
      clientId={clientId}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      authorizationParams={{
        audience,
        redirect_uri: window.location.origin,
        scope: 'allow:login',
        orgId: orgId,
      }}
    >
      {children}
    </Auth0Provider>
  )
}
