import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { deleteExport, downloadZip } from '../../services/FileDownloadService'
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import { ExportInfo, ExportStatus } from '../../types'
import { format, formatDistanceToNow } from 'date-fns'
import { useAnalytics } from '../../hooks/useAnalytics'
import { logEvent } from 'firebase/analytics'
import { IHttpClient } from '../../infrastructure'

export const ProgressPanel = ({
  items,
  httpClient,
  setWarning,
  loadExportInfo,
  reset,
  openConfirmModal,
  closeModal,
}: {
  items: ExportInfo[]
  httpClient: IHttpClient
  setWarning: (message: string) => void
  loadExportInfo: () => Promise<ExportInfo[] | void>
  reset: () => void
  openConfirmModal: ({ message, onConfirm }: { message: string; onConfirm: () => void }) => void
  closeModal: () => void
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const analytics = useAnalytics()

  useEffect(() => {
    setWarning('')
    if (items.every((i) => i.status === ExportStatus.Completed)) return
    const timer = setInterval(() => {
      loadExportInfo().then((data) => {
        if (data?.every((d) => d.status !== ExportStatus.InProgress)) {
          clearInterval(timer)
        }
      })
    }, 5000)
    return () => clearInterval(timer)
  }, [])

  const handleClose = useCallback(() => {
    closeModal()
  }, [])

  const handleClear = useCallback(() => {
    openConfirmModal({
      message: 'This will clear all unsaved files. Are you sure you want to continue?',
      onConfirm: () => {
        setIsLoading(true)
        const promises = items.map((item) => deleteExport(item.patientId, httpClient))
        Promise.all(promises)
          .then(() => {
            reset()
          })
          .catch(({ message }) => {
            setWarning(message)
          })
          .finally(() => {
            setIsLoading(false)
          })
      },
    })
  }, [])

  const handleDownload = useCallback((patientId: string, file: string) => {
    setIsDownloading(true)

    logEvent(analytics, 'export_activity', { action: 'download' })
    downloadZip({ patientId, file }, httpClient)
      .catch((reason) => {
        setWarning(reason)
      })
      .finally(() => {
        setIsDownloading(false)
      })
  }, [])

  return (
    <>
      <div className="v-modal-content">
        <Typography sx={{ fontSize: 18, marginBottom: -2, paddingLeft: 2 }}>Current Export</Typography>
        <Grid item xs={12} md={6}>
          {items.map((item) => (
            <List key={item.file}>
              <ListItem
                secondaryAction={
                  isDownloading || item.status === ExportStatus.InProgress ? (
                    <div className="v-spinner" />
                  ) : item.status === ExportStatus.Completed ? (
                    <IconButton
                      className="v-iconButton-download"
                      edge="end"
                      sx={{ background: '#eee' }}
                      onClick={() => {
                        handleDownload(item.patientId, item.file)
                      }}
                      aria-label="download"
                    >
                      <span className="fontIcon-large">download</span>
                    </IconButton>
                  ) : (
                    <span className="fontIcon-large" style={{ color: '#999' }}>
                      warning
                    </span>
                  )
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <span className="fontIcon">document</span>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText secondary={isDownloading ? 'Downloading...' : toStatusText(item.status, item.created)}>
                  <div>{toDisplayText(item)}</div>
                </ListItemText>
              </ListItem>
            </List>
          ))}
        </Grid>
      </div>
      <div className="v-modal-footer">
        <DialogActions>
          <div className="v-modal-buttonContainer">
            <Button variant="contained" disabled={isLoading} disableRipple onClick={handleClear}>
              Start A New Export
              {isLoading ? <span style={{ marginLeft: 8 }} className="v-spinner--small" /> : null}
            </Button>
          </div>
          <Button variant="outlined" disableRipple onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </div>
    </>
  )
}

const toStatusText = (status: number, created: Date) => {
  switch (status) {
    default:
    case ExportStatus.InProgress:
      return `(In progress) since ${formatDistanceToNow(created, { includeSeconds: true })} ago`
    case ExportStatus.Completed:
      return 'Export succeeded, ready for download'
    case ExportStatus.Failed:
      return 'Export timed out'
  }
}

const toDisplayText = (item: ExportInfo): string => {
  const start = `${format(item.start, 'dd MMM yyyy')}`
  const end = `${format(item.end, 'dd MMM yyyy')}`
  const label = '[Range]'
  if (start === end) return `${label} ${start}`

  return `${label} ${start} - ${end}`
}
