import React, { FC } from 'react'

export const NoDataTile: FC = () => {
  return (
    <div className="v-metricTileContainer">
      <div className="v-metricTile u-noSelect">
        <div className="v-metricTile-display">
          <div className="v-metricTile-value v-metricTile-value--long">-</div>
        </div>
      </div>
    </div>
  )
}
