import { FC, ReactNode, StrictMode, useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { Sidebar, TopBar, Dashboard, PatientPage, PatientListPage, Info } from './components'
import { loadInitialData, useDisplayContext, useLoginContext, useModulesSettings, useGroupSubscription, useWasmSetting } from './hooks'
import { PlatformPage } from './components/PlatformPage'
import { makeModuleLinkUrl } from './utils'
import { SystemPage } from './components/system/SystemPage'
import { uiSlice } from './store'
import { useDispatch } from 'react-redux'
import { PatientModuleSettings } from './types'
import * as Sentry from '@sentry/react'
import { useInstanceSetting } from './infrastructure'
import { usePatientDeviceDataSubscription } from './hooks/usePatientDeviceDataSubscription'

const makeModulePage = (name: string) => {
  switch (name) {
    case 'dashboard':
      return (
        <ViewWithTopBar>
          <Dashboard />
        </ViewWithTopBar>
      )
    case 'patients':
      return (
        <ViewWithTopBar>
          <PatientListPage />
        </ViewWithTopBar>
      )
    case 'patient':
      return <PatientPage />
  }
}

const WardFrame: FC<{ moduleList: string[] }> = ({ moduleList }) => {
  const { wardId } = useParams()
  useGroupSubscription(parseInt(wardId || ''))
  usePatientDeviceDataSubscription(parseInt(wardId || ''))

  return (
    <div id="app" className="v-rootPage">
      <Sidebar moduleList={moduleList} />
      <Outlet />
    </div>
  )
}

const Frame: FC<{ moduleList: string[] }> = ({ moduleList }) => {
  return (
    <div id="app" className="v-rootPage">
      <Sidebar moduleList={moduleList} />
      <Outlet />
    </div>
  )
}

const ViewWithTopBar: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="v-contentView">
      <TopBar />
      {children}
    </div>
  )
}

const PlatformView: FC = () => {
  return (
    <div id="app" className="flex">
      <Sidebar moduleList={[]} />
      <ViewWithTopBar>
        <PlatformPage />
      </ViewWithTopBar>
    </div>
  )
}

const InnerApp: FC = () => {
  const { defaultWard } = useLoginContext()
  const moduleSettings = useModulesSettings()
  const moduleList = Object.keys(moduleSettings)
  moduleList.sort((a, b) => (moduleSettings[b].orderInMenu || 0) - (moduleSettings[a].orderInMenu || 0))
  const { pageUrl, urlParams } = moduleSettings[moduleList[0]]
  const defaultRoute = makeModuleLinkUrl({ wardId: '' + defaultWard }, pageUrl, urlParams)
  const dispatch = useDispatch()

  useEffect(() => {
    const start = useDisplayContext().start.getTime()
    dispatch(uiSlice.actions.setDataRange({ value: { start } }))
  }, [])

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

  return (
    <SentryRoutes>
      <Route path="/" element={<Navigate to={defaultRoute} replace={true} />} />
      <Route path="/ward/:wardId" element={<WardFrame moduleList={moduleList} />}>
        {moduleList.map((moduleName) => {
          const { pageUrl } = moduleSettings[moduleName]
          return !pageUrl.startsWith('/ward/:wardId') ? null : (
            <Route key={moduleName} path={pageUrl} element={makeModulePage(moduleName)} />
          )
        })}
      </Route>
      <Route path="/platform" element={<PlatformView />} />
      <Route path="/ward/:wardId" element={<Frame moduleList={moduleList} />}>
        <Route
          path="settings"
          element={
            <ViewWithTopBar>
              <SystemPage />
            </ViewWithTopBar>
          }
        />
      </Route>
    </SentryRoutes>
  )
}

export const App: FC = Sentry.withProfiler(() => {
  const { ready } = loadInitialData()
  const instanceSetting = useInstanceSetting()

  useEffect(() => {
    if (instanceSetting?.userFeedbackFormEnabled ?? false) {
      const client = Sentry.getClient()
      if (client) {
        Sentry.getFeedback()?.createWidget()
      }
    }
  }, [instanceSetting])

  if (!ready) return <Info text="Loading ..." />

  let { wasmChartEnabled } = useWasmSetting()
  return wasmChartEnabled ? (
    <StrictMode>
      <InnerApp />
    </StrictMode>
  ) : (
    <InnerApp />
  )
})
