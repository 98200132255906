import { gql, useQuery } from '@apollo/client'
import { parseISO } from 'date-fns'
import { GraphQLResult } from '../types'

const QUERY_FIRST_SESSION_DATE = gql`
  query QueryFirstSessionDate($patientId: UUID!) {
    result: firstSessionDate(patientId: $patientId)
  }
`

export const useFirstSessionDate = (patientId: string) => {
  const { loading, data, error } = useQuery<GraphQLResult<string>>(QUERY_FIRST_SESSION_DATE, {
    variables: { patientId },
  })
  if (!loading && !error && data && data.result) {
    const firstSessionDate = data.result ? parseISO(data.result) : undefined
    return firstSessionDate
  }

  return
}
